import { makeAutoObservable } from 'mobx';

export class UserStore {
  user = null;
  isAdmin = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getToken = async () => {
    return await this.user?.getIdToken(true);
  };

  setUser = (user, isAdmin) => {
    this.user = user;
    this.isAdmin = isAdmin;
  };
}
