import { makeAutoObservable } from 'mobx';

export class DocumentStore {
  documents = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setDocuments = (documents) => {
    this.documents = documents;
  };
}
