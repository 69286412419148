import styled from 'styled-components';
import { colors } from 'Constants/colors';

export const Button = styled.button`
  background-color: white;
  color: ${(props) => props.color ? props.color : colors.royalBlue2};
  border: 2px solid ${colors.royalBlue2};
  box-shadow: 3px 3px ${colors.royalBlue2};
  padding: 8px;
  min-width: ${(props) => props.minWidth || '100%'};
  margin-top: ${(props) => props.marginTop}px;
  font-size: ${(props) => (props.isSmall ? '16px' : '20px')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  border-radius: ${(props) => (props.radius ? props.radius : 15)}px;
  line-height: ${(props) => (props.isSmall ? '22px' : '28px')};
  font-family: Kastelov;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.disabled ? colors.gray : colors.blueViolet)};
    color: white;
  }

  &:focus {
    outline: 0;
  }
`;
