import { Page } from 'Components/Page';
import React from 'react';
import styled from 'styled-components';
import backgroundImage from 'Images/login-background.png';
import titleImage from 'Images/login-title.png';
import { Card } from 'Components/Basic/Card';
import { colors } from '../Constants/colors';

export const SmallScreenWarning = () => {
  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <ContentWrapper>
        <TitleImage src={titleImage} alt="INEA" />
        <Card marginBottom={64} maxWidth={400}>
          <Text>Aplikacja jest dostępna jedynie na urządzeniach o dużej szerokości ekranu (powyżej 1024px).</Text>
        </Card>
      </ContentWrapper>
    </Page>
  );
};

const Text = styled.div`
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const TitleImage = styled.img`
  width: 650px;
  max-width: 90%;
  height: auto;
  margin-bottom: 50px;
`;
