import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Page } from 'Components/Page';
import { ButtonTextWrapper, ContentWrapper, Message } from './Login';
import { Card } from 'Components/Basic/Card';
import { Input } from 'Components/Basic/Input';
import styled from 'styled-components';
import backgroundImage from '../Images/login-background.png';
import { colors } from '../Constants/colors';
import { Button } from '../Components/Basic/Button';
import ButtonText from '../Components/ButtonText';
import { routes } from '../Constants/routes';

const defaultErrorMessage = 'Wystąpił bład podczas resetu hasła, spróbuj ponownie później.';

export const PasswordReset = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onPasswordReset = async (e) => {
    e.preventDefault();
    if (email && email.length > 0) {
      firebase.auth().sendPasswordResetEmail(email)
        .then(function() {
          // Password reset email sent.
        })
        .catch(function(error) {
          // do nothing
        });
      setSuccess("Sprawdź swoją skrzynkę mailową")
    } else {
      setError('Proszę wpisać adres email');
    }
  };

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [email]);

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <ContentWrapper>
        <Card marginBottom={64} maxWidth={400}>
          <form onSubmit={onPasswordReset}>
            <h3>Wprowadź adres email swojego konta.</h3>
            <InputWrapper>
              <Input
                placeholder='Email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </InputWrapper>
            {error && <Message error>{typeof error === 'string' ? error : defaultErrorMessage}</Message>}
            {success && <Message>{success}</Message>}
            <InputWrapper>
              <Button>Resetuj</Button>
            </InputWrapper>
          </form>
          <ButtonTextWrapper>
            <ButtonText to={routes.LOGIN}>Wróć</ButtonText>
          </ButtonTextWrapper>
        </Card>
      </ContentWrapper>
    </Page>
  );
};

const InputWrapper = styled.div`
  padding: 8px 16px;
`;
