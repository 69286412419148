import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useEffect } from 'react';
import { routes } from '../../Constants/routes';
import styled from 'styled-components';


export const ErrorBox = () => {
  useEffect(() => {
       window.location.href = routes.MODULES
  }, [])

  //todo optional set some kind of temporary notification that something bad happened.

  return (
      <Wrapper>
        <Loader indeterminate>Coś poszło nie tak...</Loader>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%
`


export default ErrorBox;