import { useCallback } from 'react';
import { useStores } from './useStores';

const API_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_API_URL;

export const useApi = () => {
  const { userStore } = useStores();

  const callApi = useCallback(
    async (url, options = {}) => {
      const headers = {
        Authorization: 'Bearer ' + (await userStore.getToken()),
        'Content-Type': 'application/json',
      };
      const parsedUrl = API_URL + url + parseUrlParams(options.params);

      const res = await fetch(parsedUrl, {
        headers,
        ...options,
        ...(options.body && { body: JSON.stringify(options.body) }),
      });
      if (res.status === 205) {
        return res.status
      } else {
        return await res.json();
      }
    },
    [userStore]
  );

  const parseUrlParams = (params = null) => {
    if (params) {
      return (
        '?' +
        Object.keys(params)
          .reduce(function (_qs, k, i) {
            return _qs + '&' + k + '=' + params[k];
          }, '')
          .substring(1)
      );
    } else {
      return '';
    }
  };

  const getUsers = useCallback(async () => {
    if (userStore.user) {
      return await callApi('/users');
    }
  }, [callApi, userStore]);

  const updateUserActions = useCallback(
    async (action) =>
      userStore.user &&
      (await callApi('/user-details/' + userStore.user.uid + '/actions', { method: 'PATCH', body: { action } })),
    [userStore, callApi]
  );

  const getModules = useCallback(async () => await callApi('/modules'), [callApi]);

  const registerUser = useCallback(
    async (action) => {
      return await callApi('/users/register', { method: 'POST', body: action });
    },
    [callApi]
  );

  const getUserGameState = useCallback(
    async (action) => {
      return await callApi('/game/state', { method: 'GET', body: action });
    },
    [callApi]
  );

  const getScoring = useCallback(
    async (params) => {
      return await callApi('/scoring', { method: 'GET', params: params });
    },
    [callApi]
  );

  const getMyScoring = useCallback(async () => {
    return await callApi('/scoring/me', { method: 'GET' });
  }, [callApi]);

  const updateUserGameState = useCallback(
    async (module, slideId) => {
      return await callApi('/game/state', { method: 'POST', body: { module, slideId } });
    },
    [callApi]
  );

  const updateUserModuleProgress = useCallback(
    async ({ module, finished }) => {
      await callApi('/game/state/module', { method: 'POST', body: { module, finished } });
    },
    [callApi]
  );

  const resetUserGameState = useCallback(
    async (module) => {
      await callApi('/game/state', { method: 'PUT', body: { module } });
    },
    [callApi]
  );

  //admin
  const resetUserGameStateByUid = useCallback(
    async ({ uid, module }) => {
      await callApi('/game/state/reset', { method: 'PUT', body: { uid, module } });
    },
    [callApi]
  );

  return {
    callApi,
    getUsers,
    getScoring,
    updateUserActions,
    registerUser,
    getModules,
    getUserGameState,
    updateUserGameState,
    resetUserGameState,
    getMyScoring,
    updateUserModuleProgress,
    resetUserGameStateByUid
  };
};
