import { makeAutoObservable } from 'mobx';
import Cookies from 'js-cookie';

export class ConfigStore {
  fullScreenRef = null;
  isFullscreen = false;
  isSoundOn = true;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    const soundOn = Cookies.get('sound');
    this.isSoundOn = soundOn !== undefined ? soundOn === 'true' : true;
  }

  getFullScreenComponentRef = () => {
    return this.fullScreenRef;
  };

  setFullScreenRef = (ref) => {
    this.fullScreenRef = ref;
  };

  setIsSoundOn = (isSoundOn) => {
    this.isSoundOn = isSoundOn;
    Cookies.set('sound', isSoundOn);
  };

  setIsFullscreen = (isFullscreen) => {
    this.isFullscreen = isFullscreen;
  };

  setPlayBackgroundSound = (isPlaying) => {
    this.playBackgroudSound = isPlaying;
  };
}
