import { useStores } from 'Hooks/useStores';
import React, { useState } from 'react';
import { Button as SemanticButton, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { DocumentModal } from './DocumentsModal';
import { useApi } from 'Hooks/useApi';
import { colors } from 'Constants/colors';

export const NavButtons = () => {
  const { gameStore, userStore } = useStores();
  const { resetUserGameState } = useApi();

  const [open, setOpen] = useState(false);

  const resetModuleProgress = async () => {
    gameStore.resetModule(gameStore.currentModule);
    await resetUserGameState(gameStore.currentModule);
  };

  return (
    <NavButtonWrapper>
      {userStore.isAdmin && <NavbarOptionButton onClick={resetModuleProgress} text="Reset" iconName="repeat" />}
      {/*<NavbarOptionButton onClick={goToMap} text="Mapa" iconName="map outline" />*/}
      <NavbarOptionButton onClick={() => setOpen(true)} text="Aktówka" iconName="folder open" />
      <DocumentModal open={open} setOpen={setOpen} />
    </NavButtonWrapper>
  );
};

const NavButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  padding: 16px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Button = styled(SemanticButton)`
  &&&.button {
    background-color: white;
    color: ${colors.blueViolet};
    border: 2px solid white;
    border-radius: 8px;
    transition: background-color 0.4s, color 0.4s;
  }
  &&&.button:hover {
    background-color: ${colors.blueViolet};
    color: white;
  }
`;

export const NavbarOptionButton = ({ onClick, text, iconName }) => {
  return (
    <Button size="tiny" color="black" animated="vertical" onClick={onClick}>
      <Button.Content hidden>{text}</Button.Content>
      <Button.Content visible>
        <Icon name={iconName} />
      </Button.Content>
    </Button>
  );
};
