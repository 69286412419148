import styled from 'styled-components';

const PageComponent = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  background-image: url(${props => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: ${props => props.align};
`;

export const Page = ({ children, ...props }) => {
  return <PageComponent {...props}>{children}</PageComponent>;
};
