import styled from 'styled-components';
import { colors } from 'Constants/colors';

export const TextBox = ({ children, ...props }) => {
  return <TextWrapper {...props}>{children}</TextWrapper>;
};

const TextWrapper = styled.div.attrs((props) => ({
  padding: props.padding || '16px',
}))`
  user-select: none;
  position: relative;
  border: 3px solid ${colors.royalBlue2};
  box-shadow: 3px 3px ${colors.royalBlue2};
  font-size: ${(props) => props.isSmall ? '16px' : '20px'};
  font-weight: ${(props) => props.bold ? 'bold' : 'normal'};
  border-radius: ${(props) => props.radius ? props.radius : 16}px;
  background-color: ${(props) => props.disabled ? colors.gray : 'white'};
  line-height: ${(props) => props.isSmall ? '22px' : '28px'};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  cursor: ${(props) => props.cursor ? props.cursor : 'default'};
  color: ${(props) => props.disabled ? 'white' : colors.royalBlue2};
  opacity: ${(props) => props.disabled ? 0.8 : 1};
  ${(props) => props.hover ? (
    props.disabled ? `&:hover { background-color: ${colors.gray} }'`
      : `&:hover { background-color: white }`
  ) : ''}
`;
