import styled from 'styled-components';
import { colors } from 'Constants/colors';

export const Input = styled.input`
  background-color: white;
  width: 100%;
  color: ${colors.royalBlue2};
  border: 2px solid ${colors.royalBlue2};
  font-size: ${(props) => (props.isSmall ? '16px' : '20px')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  line-height: ${(props) => (props.isSmall ? '22px' : '28px')};
  border-radius: ${(props) => (props.radius ? props.radius : 15)}px;
  padding: 8px 16px;
  opacity: 0.9;

  &:focus {
    outline: 0;
  }
`;
