import { Dropdown, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { useStores } from 'Hooks/useStores';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { routes } from 'Constants/routes';
import { colors } from 'Constants/colors';
import { useLocation } from 'react-router-dom';

import logo from 'Images/logo.svg';
import { NavButtons } from './Slides/NavButtons';
import { observer } from 'mobx-react';
import { useApi } from '../Hooks/useApi';

const EmailWrapper = styled.div`
  color: white;
  margin-right: 64px;
  font-size: 12px;
`;

const UserDisplayNameWrapper = styled.div`
  color: white;
  margin-right: 8px;
  font-weight: 700;
`;

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${colors.royalBlue2};
  padding: 0 16px;
`;

const NavbarLogo = styled.img`
  height: 35px;
  width: auto;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Navbar = observer(() => {
  const { userStore, gameStore } = useStores();
  const [activeItem, setActiveItem] = useState('/');
  const history = useHistory();
  const { getUserGameState } = useApi();

  const location = useLocation();

  function handleClick(e, { name }) {
    setActiveItem(name);
    history.push(name);
  }

  const goToMap = async (e, rest) => {
    gameStore.resetGameState();
    const userGameState = await getUserGameState();
    gameStore.setUserGameState(userGameState);
    handleClick(e, rest)
  };

  const onLogout = () => {
    setActiveItem(routes.MODULES);
    firebase
      .auth()
      .signOut()
      .then(function () {
        userStore.setUser(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <NavbarWrapper size="small" inverted>
      <LeftWrapper>
        <NavbarLogo src={logo} />
      </LeftWrapper>
      {location.pathname === '/' && gameStore.currentModule && <NavButtons />}
      <RightWrapper>
        {userStore.user && (
          <>
            <UserDisplayNameWrapper>{userStore.user?.displayName}</UserDisplayNameWrapper>
            <EmailWrapper>({userStore.user?.email})</EmailWrapper>
            <DropdownStyled text="MENU" item>
              <Dropdown.Menu direction="left">
                <MenuItemStyled name={routes.MODULES} active={activeItem === routes.MODULES} onClick={goToMap}>
                  Mapa
                </MenuItemStyled>
                {userStore.isAdmin && (
                  <>
                    <MenuItemStyled name={routes.ADMIN} active={activeItem === routes.ADMIN} onClick={handleClick}>
                      Panel admina
                    </MenuItemStyled>
                    <MenuItemStyled active={activeItem === routes.DASHBOARD} name={routes.DASHBOARD} onClick={handleClick}>
                      Tablica wyników
                    </MenuItemStyled>
                  </>
                )}
                <MenuItemStyled onClick={onLogout}>Wyloguj</MenuItemStyled>
              </Dropdown.Menu>
            </DropdownStyled>
          </>
        )}
      </RightWrapper>
    </NavbarWrapper>
  );
});

const MenuItemStyled = styled(Menu.Item)`
  &&&.item {
    color: ${colors.blueViolet};
    padding: 16px;
    font-weight: bold;
  }
  &&&.item.active,
  &&&.item:hover {
    background-color: ${colors.blueViolet};
    color: white;
  }
`;

const DropdownStyled = styled(Dropdown)`
  color: white;
  font-weight: bold;
  user-select: none;

  &&& .menu {
    border: 2px solid white;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 6px;
  }
  .icon {
    display: none;
  }
`;
