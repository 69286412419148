import { Page } from 'Components/Page';
import { useApi } from 'Hooks/useApi';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import backgroundImage from 'Images/login-background.png';
import { Card } from 'Components/Basic/Card';
import { Table } from 'Components/Basic/Table';
import { colors } from '../Constants/colors';
import firebase from 'firebase';
import { ExportScores } from '../Components/Admin/ExportScores';
import { ExportEmails } from '../Components/Admin/ExportEmails';
import { ButtonWithConfirmation } from '../Components/Admin/ButtonWithConfirmation';

const Content = styled.div`
  width: ${(props) => (props.maxWidth ? '100%' : '600px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const moduleOptions = [
  { key: 'm1', text: 'Moduł 1', value: 'm1' },
  { key: 'm2', text: 'Moduł 2', value: 'm2' },
  { key: 'm3', text: 'Moduł 3', value: 'm3' },
  { key: 'm4', text: 'Moduł 4', value: 'm4' },
  { key: 'm5', text: 'Moduł 5', value: 'm5' },
  { key: 'm6', text: 'Moduł 6', value: 'm6' },
  { key: 'm7', text: 'Moduł 7', value: 'm7' },
];

export const Admin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getUsers, resetUserGameStateByUid } = useApi();

  useEffect(() => {
    (async function fetchUsers() {
      try {
        const response = await getUsers();
        setUsers(response ? response.users : []);
      } catch (e) {
        console.error(e);
        setUsers([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [getUsers]);

  const passwordReset = (email) => {
    if (email && email.length > 0) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          alert('Reset email sent');
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  const onResetModuleState = (user, module) => {
    resetUserGameStateByUid({ uid: user.uid, module: module }).then(() => alert(`Stan modułu: ${module} zresetowany`));
  };

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <Content maxWidth>
        <Dimmer inverted active={!!loading}>
          <Loader />
        </Dimmer>
        <Card overflowX={'auto'} maxWidth={'100%'}>
          {/*<Mailing/>*/}
          <h3>Użytkownicy</h3>
          <ExportScores users={users} />
          <ExportEmails users={users} />
          <Table minWidth={1400} basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Reset hasła (email)</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 7</Table.HeaderCell>
                <Table.HeaderCell>Module 7 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 7 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 6</Table.HeaderCell>
                <Table.HeaderCell>Module 6 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 6 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 5</Table.HeaderCell>
                <Table.HeaderCell>Module 5 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 5 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 4</Table.HeaderCell>
                <Table.HeaderCell>Module 4 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 4 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 3</Table.HeaderCell>
                <Table.HeaderCell>Module 3 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 3 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 2</Table.HeaderCell>
                <Table.HeaderCell>Module 2 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 2 uzyskany wynik</Table.HeaderCell>
                <Table.HeaderCell>Reset stanu gry modulu 1</Table.HeaderCell>
                <Table.HeaderCell>Module 1 podjęte próby</Table.HeaderCell>
                <Table.HeaderCell>Module 1 uzyskany wynik</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users &&
                users.map((user, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{user.uid}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'mail'} onClick={() => passwordReset(user.email)} />
                    </Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm7')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m7?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m7?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm6')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m6?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m6?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm5')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m5?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m5?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm4')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m4?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m4?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm3')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m3?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m3?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm2')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m2?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m2?.score}</Table.Cell>
                    <Table.Cell>
                      <ButtonWithConfirmation icon={'redo'} onClick={() => onResetModuleState(user, 'm1')} />
                    </Table.Cell>
                    <Table.Cell>{user.games?.m1?.attempts}</Table.Cell>
                    <Table.Cell>{user.games?.m1?.score}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Card>
      </Content>
    </Page>
  );
};
