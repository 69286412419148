import React, { useEffect } from 'react';
import { useFirebase } from 'Hooks/useFirebase';
import { Router } from 'Pages';
import { colors } from 'Constants/colors';
import Kastelov from 'Fonts/Kastelov-Axiforma-Regular.otf';
import styled, { createGlobalStyle } from 'styled-components';
import { SmallScreenWarning } from 'Pages/SmallScreenWarning';
import { useStores } from './Hooks/useStores';
import 'semantic-ui-css/semantic.min.css';

function App() {
  const { loading } = useFirebase();
  const { configStore } = useStores();
  const maximizableElement = React.useRef(null);

  useEffect(() => {
    configStore.setFullScreenRef(maximizableElement)
  }, [maximizableElement.current])

  return (
    <>
      <GlobalStyle />
      <HideWhenScreenSmall ref={maximizableElement}>
        <div id="mountModal"/>
        {!loading && <Router />}
      </HideWhenScreenSmall>
      <ShowWhenScreenSmall>
        <SmallScreenWarning />
      </ShowWhenScreenSmall>
    </>
  );
}

const HideWhenScreenSmall = styled.div`
  display: none;
  height: 100%;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const ShowWhenScreenSmall = styled.div`
  display: none;
  height: 100%;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: Kastelov;
    src: url('${Kastelov}');
  }

  body {
    font-family: Kastelov;
    color: ${colors.royalBlue2};
  }
`;

export default App;
