import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { array } from 'prop-types';
import { Icon } from 'semantic-ui-react';

export const ExportScores = ({ users }) => {
  return (
    <div>
      <CSVLink
        filename={'export_danych.csv'}
        headers={[
          { key: 'id', label: 'System ID' },
          { key: 'email', label: 'Email użytkownika' },
          { key: 'module1finishedDate', label: 'Moduł 1 - data zakończenia' },
          { key: 'module1attempts', label: 'Moduł 1 - podjęte próby' },
          { key: 'module1score', label: 'Moduł 1 - uzyskany wynik' },
          { key: 'module2finishedDate', label: 'Moduł 2 - data zakończenia' },
          { key: 'module2attempts', label: 'Moduł 2 - podjęte próby' },
          { key: 'module2score', label: 'Moduł 2 - uzyskany wynik' },
          { key: 'module3finishedDate', label: 'Moduł 3 - data zakończenia' },
          { key: 'module3attempts', label: 'Moduł 3 - podjęte próby' },
          { key: 'module3score', label: 'Moduł 3 - uzyskany wynik' },
          { key: 'module4finishedDate', label: 'Moduł 4 - data zakończenia' },
          { key: 'module4attempts', label: 'Moduł 4 - podjęte próby' },
          { key: 'module4score', label: 'Moduł 4 - uzyskany wynik' },
          { key: 'module5finishedDate', label: 'Moduł 5 - data zakończenia' },
          { key: 'module5attempts', label: 'Moduł 5 - podjęte próby' },
          { key: 'module5score', label: 'Moduł 5 - uzyskany wynik' },
          { key: 'module6finishedDate', label: 'Moduł 6 - data zakończenia' },
          { key: 'module6attempts', label: 'Moduł 6 - podjęte próby' },
          { key: 'module6score', label: 'Moduł 6 - uzyskany wynik' },
          { key: 'module7finishedDate', label: 'Moduł 7 - data zakończenia' },
          { key: 'module7attempts', label: 'Moduł 7 - podjęte próby' },
          { key: 'module7score', label: 'Moduł 7 - uzyskany wynik' },
        ]}
        data={users.map((user) => ({
          id: user.uid,
          email: user.email,
          module1attempts: user.games?.m1?.attempts,
          module1score: user.games?.m1?.score,
          module1finishedDate: user.games?.m1?.finishedDate
            ? moment
                .utc(user.games?.m1?.finishedDate?.slice(0, user.games?.m1?.finishedDate.length - 4))
                .local()
                .format('YYYY-MM-DD HH:mm')
            : '',
          module2attempts: user.games?.m2?.attempts,
          module2score: user.games?.m2?.score,
          module2finishedDate: user.games?.m2?.finishedDate
            ? moment
                .utc(user.games?.m2?.finishedDate?.slice(0, user.games?.m2?.finishedDate.length - 4))
                .local()
                .format('YYYY-MM-DD HH:mm')
            : '',
          module3attempts: user.games?.m3?.attempts,
          module3score: user.games?.m3?.score,
          module3finishedDate: user.games?.m3?.finishedDate
            ? moment
                .utc(user.games?.m3?.finishedDate?.slice(0, user.games?.m3?.finishedDate.length - 4))
                .local()
                .format('YYYY-MM-DD HH:mm')
            : '',
          module4attempts: user.games?.m4?.attempts,
          module4score: user.games?.m4?.score,
          module4finishedDate: user.games?.m4?.finishedDate
            ? moment
                .utc(user.games?.m4?.finishedDate?.slice(0, user.games?.m4?.finishedDate.length - 4))
                .local()
                .format('YYYY-MM-DD HH:mm')
            : '',
          module5attempts: user.games?.m5?.attempts,
          module5score: user.games?.m5?.score,
          module5finishedDate: user.games?.m5?.finishedDate
            ? moment
              .utc(user.games?.m5?.finishedDate?.slice(0, user.games?.m5?.finishedDate.length - 4))
              .local()
              .format('YYYY-MM-DD HH:mm')
            : '',
          module6attempts: user.games?.m6?.attempts,
          module6score: user.games?.m6?.score,
          module6finishedDate: user.games?.m6?.finishedDate
            ? moment
              .utc(user.games?.m6?.finishedDate?.slice(0, user.games?.m6?.finishedDate.length - 4))
              .local()
              .format('YYYY-MM-DD HH:mm')
            : '',
          module7attempts: user.games?.m7?.attempts,
          module7score: user.games?.m7?.score,
          module7finishedDate: user.games?.m7?.finishedDate
            ? moment
              .utc(user.games?.m7?.finishedDate?.slice(0, user.games?.m7?.finishedDate.length - 4))
              .local()
              .format('YYYY-MM-DD HH:mm')
            : '',
        }))}
      >
        Eksportuj wyniki do pliku csv
        <Icon name={'save'} style={{ marginLeft: '5px' }} />
      </CSVLink>
    </div>
  );
};

ExportScores.propTypes = {
  users: array,
};
