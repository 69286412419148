import { Modal } from '../Basic/Modal';
import { Accordion, Header, Icon } from 'semantic-ui-react';
import { IconWrapper } from './Config';
import { useState } from 'react';
import styled from 'styled-components';

export const InstructionsModal = () => {
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const accordionConfig = [
    {
      title: 'Nie wiesz jak zacząć grę?',
      content: 'To proste! Na planecie, którą widzisz na ekranie początkowym, kliknij  w dużą kropkę obok nazwy modułu, który chcesz przejść (a którego nie ukończyłaś/-eś i jest on dostępny). To kliknięcie przeniesie Cię prosto do jednego z rozdziałów historii w grze “Kosmos możliwości”.',
    },
    {
      title: 'Nie wiesz jak grać?',
      content: 'Potraktuj to jak książkę, którą czytasz ale w której możesz samodzielnie podejmować wiele decyzji. Na każdym z kolejnych kroków zobaczysz scenografię oraz tekst, w dużej białej ramce. A pod nim możliwe odpowiedzi - czasami jedną, czasem dwie a innym razem nawet trzy. Wybieraj, decyduj, i bądź częścią tej historii.',
    },
    {
      title: 'Nie masz pewności, jaką decyzję podjąć?',
      content: 'Jeśli decyzja dotyczy kwestii merytorycznych i czujesz, że nie masz jeszcze wiedzy, aby podjąć ją bezbłędnie to zawsze możesz zajrzeć do swojej Aktówki. Powinna być widoczna na górze ekranu, po przejściu do jednego z modułów i przypomina ikonę folderu (nie jest widoczna na ekranie startowym). Wewnątrz niej znajdziesz firmowe dokumenty i regulacje, które mogą pomóc Ci podjąć najlepszą decyzję. W decyzjach, którym bliżej do codziennych i niecodziennych działań i zachowań to… Ty tu decydujesz, to od Ciebie zależy jak potoczy się historia :)',
    },
    {
      title: 'Jesteś w trakcie gry i musisz przerwać? ',
      content: 'Nie martw się, po kolejnym zalogowaniu wrócisz do miejsca, gdzie przerwałaś lub przerwałeś historię.',
    },
    {
      title: 'Chcesz wrócić do ekranu startowego w trakcie modułu?',
      content: 'Nic prostszego - w menu widocznym w prawym górnym rogu wybierz opcję "Mapa".',
    },
    {
      title: 'Chcesz wrócić do gry?',
      content: 'Po prostu kliknij w odpowiedni moduł, tak jak przy rozpoczynaniu gry.',
    },
    {
      title: 'Moduł ukończony a Ty nie wiesz, jak Ci poszło?',
      content: 'Kliknij w ukończony moduł - tam znajdziesz informację o swoim wyniku.',
    },
    {
      title: 'Masz trudności spoza listy?',
      content: 'Skontaktuj się z Adamem Woźniakiem poprzez platformę Teams.',
    },
  ];

  return (
    <IconWrapper>
      <Modal size='large' open={instructionOpen} onClose={() => setInstructionOpen(false)}>
        <Header>Hej! Nie wiesz co robić? Jeśli masz kłopot to spróbuj jednej z możliwości poniżej:</Header>
        <Accordion>
          {accordionConfig.map((ele, idx) => (
            <AccordionElement
              key={idx}
              title={ele.title}
              content={ele.content}
              idx={idx}
              activeIndex={activeIndex}
              handleClick={handleClick}
            />
          ))}
        </Accordion>
        <Caution>
          <b>Uwaga:</b> czasami, w przypadku niektórych rozdzielczości i wielkości ekranów, kiedy tekstu jest więcej, sam ekran może wydawać się jakby ucięty na dole. Warto wtedy <b>ruszyć rolką myszki lub paskiem bocznym w przeglądarce w dół.</b> Niech żadna możliwa opcja odpowiedzi Cię nie ominie.
        </Caution>
        <GoodLuck>Przyjemnej rozgrywki! Powodzenia!</GoodLuck>
      </Modal>
      <Icon onClick={() => setInstructionOpen(true)} size='big' name='question circle' />
    </IconWrapper>
  );
};

const AccordionElement = ({ title, content, idx, activeIndex, handleClick }) => (
  <>
    <Accordion.Title
      active={activeIndex === idx}
      index={idx}
      onClick={handleClick}
    >
      <Icon name='dropdown' />
      {title}
    </Accordion.Title>
    <Accordion.Content active={activeIndex === idx}>
      <Paragraph>{content}</Paragraph>
    </Accordion.Content>
  </>
);

const Paragraph = styled.p`
    padding: 0 20px 5px 20px;
`

const Caution = styled.p`
    margin-top: 20px;
`

const GoodLuck = styled(Header)`
  display: flex;
  justify-content: center;
`
