import { makeAutoObservable } from 'mobx';

export class GameStore {
  slide = 1;
  currentModule = null;
  currentModuleFinished = false;
  currentModuleScore = {};
  currentUserPath = [];
  modules = [];
  userGameState = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get activeModules() {
    return this.modules.filter((module) => !module.disabled);
  }

  get currentModuleSlides() {
    return (this.modules.find((module) => module.name === this.currentModule) || {}).slides;
  }

  get currentModuleGameStatePath() {
    const paths = this?.userGameState?.gamePaths || {};
    return paths[this.currentModule]?.path || [];
  }

  getSlide = (slideId) => {
    if (this.currentModule) {
      return this.currentModuleSlides.find(({ id }) => slideId === id);
    }
  };

  getCurrentSlide = () => {
    return this.getSlide(this.slide);
  };

  resetModule = (currentModule) => {
    this.slide = 1;
    this.currentModule = currentModule;
    this.currentUserPath = [];
    this.currentModuleFinished = false;
  };

  resetGameState = () => {
    this.slide = 1;
    this.currentModule = null;
    this.currentUserPath = [];
    this.currentModuleFinished = false;
  };

  setSlide = (slide) => {
    this.slide = slide;
    this.currentUserPath.push(slide);
  };

  setCurrentModule = (moduleId) => {
    const isEnabled = this.activeModules.find((module) => module.name === moduleId);
    if (isEnabled) this.currentModule = moduleId;
    this.currentUserPath = this.currentModuleGameStatePath;
  };

  setModules = (modules) => {
    this.modules = modules;
  };

  setUserGameState = (userGameState) => {
    this.userGameState = userGameState;
  };

  setCurrentModuleFinished = (data) => {
    this.currentModuleFinished = true;
    this.currentModuleScore = data;
  }

  loadModuleState = () => {
    if (this.userGameState && this.userGameState.gamePaths && this.userGameState.gamePaths[this.currentModule]) {
      const path = this.userGameState.gamePaths[this.currentModule].path;
      if (path && path.length) {
        this.slide = path[path.length - 1];
      } else {
        this.slide = 1;
      }
    }
  };
}
