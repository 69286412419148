import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, allow, path, exact }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (allow ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};
