import { useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import { MAP_DIRECTORIES } from 'Constants/modules';

export const useFirebaseStorage = () => {
  const storage = firebase.storage().ref();

  const getImageUrl = useCallback(
    async (moduleId, imageId) => {
      return storage
        .child(`images/${MAP_DIRECTORIES[moduleId]}/${imageId}`)
        .getDownloadURL()
        .then((url) => {
          return url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    [storage]
  );

  const getModuleDocumentRefs = useCallback(
    async (moduleId) => {
      return storage
        .child(`documents/${MAP_DIRECTORIES[moduleId]}`)
        .listAll()
        .then((list) => {
          return list.items;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    [storage]
  );

  const getDocumentByRef = useCallback(async (ref) => {
    return ref
      .getDownloadURL()
      .then((url) => url)
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return { getImageUrl, getModuleDocumentRefs, getDocumentByRef };
};
