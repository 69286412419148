import useSound from 'use-sound';
import { useStores } from './useStores';

export const useSoundFx = (sfx, options = {}) => {

  const { configStore } = useStores();

  const [play, exposedData] = useSound(sfx, {
    ...options,
    volume: !configStore.isSoundOn ? 0 : options.volume ? options.volume : 0.2,
  });

  return {
    play,
    exposedData,
  };
};
