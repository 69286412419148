import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ModuleSelect from 'Components/Modules/ModuleSelect';
import { useStores } from 'Hooks/useStores';
import { Slideshow } from 'Components/Slides/Slideshow';
import { useFirebaseStorage } from 'Hooks/useFirebaseStorage';
import { useApi } from 'Hooks/useApi';
import { Dimmer, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { Config } from './Config/Config';
import { ModuleFinalView } from './Modules/ModuleFinalView';
import { useBackgroundSound } from 'Hooks/useBackroundSound';

export const Game = observer(() => {
  const [loading, setLoading] = useState(true);

  const { gameStore, documentStore } = useStores();
  const { getModuleDocumentRefs } = useFirebaseStorage();

  const { getModules, getUserGameState } = useApi();

  useEffect(() => {
    async function fetchDocuments() {
      documentStore.documents = await getModuleDocumentRefs(gameStore.currentModule);
    }
    if (gameStore.currentModule) {
      fetchDocuments();
    }
  }, [documentStore, getModuleDocumentRefs, gameStore, gameStore.currentModule]);

  useEffect(() => {
    (async () => {
      const modules = await getModules();
      setLoading(false);
      gameStore.setModules(modules);
    })();
  }, [gameStore, getModules, setLoading]);

  useEffect(() => {
    (async () => {
      const userGameState = await getUserGameState();
      gameStore.setUserGameState(userGameState);
    })();
  }, [gameStore, getUserGameState]);

  useBackgroundSound((gameStore.currentModuleFinished || !gameStore.currentModule) && !loading);

  return (
    <>
      <GameContainer>
        {gameStore.currentModuleFinished ? (
          <ModuleFinalView loading={loading} setLoading={setLoading} />
        ) : !gameStore.currentModule ? (
          <ModuleSelect setLoading={setLoading} />
        ) : (
          <SlideshowWrapper>
            <Slideshow loading={loading} setLoading={setLoading} />
          </SlideshowWrapper>
        )}
        <Config />
      </GameContainer>
      <Dimmer active={!!loading}>
        <Loader indeterminate>{typeof loading === 'string' ? loading : ''}</Loader>
      </Dimmer>
    </>
  );
});

const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SlideshowWrapper = styled.div`
  height: 100%;
  background-color: navy;
`;
