import { useEffect, useCallback, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useSoundFx } from './useSoundFx';
import { useStores } from './useStores';
import { MAP_DIRECTORIES } from 'Constants/modules';
import firebase from 'firebase/app';
import 'firebase/storage';

export const useGameSound = (name, loop) => {
  const storage = firebase.storage().ref();

  const [soundUrl, setSoundUrl] = useState();

  const { configStore, gameStore } = useStores();

  const isVisible = usePageVisibility();

  const { play, exposedData } = useSoundFx(soundUrl, { loop, volume: 0.05 });

  const getSoundUrl = useCallback(
    async (moduleId, soundId) => {
      return storage
        .child(`sounds/${MAP_DIRECTORIES[moduleId]}/${soundId}`)
        .getDownloadURL()
        .then((url) => {
          return url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    [storage]
  );

  useEffect(() => {
    (async () => {
      if (name && configStore.isSoundOn) {
        const url = await getSoundUrl(gameStore.currentModule, name);
        setSoundUrl(url);
      } else {
        setSoundUrl(null);
      }
    })();
  }, [name, configStore.isSoundOn]);

  useEffect(() => {
    if (!loop) {
      exposedData.stop();
    }
  }, [soundUrl, loop]);

  useEffect(() => {
    if (exposedData.duration && isVisible && configStore.isSoundOn) {
      play();
    }
    if (!isVisible) {
      exposedData.stop();
    }
    return () => exposedData.stop();
  }, [play, exposedData.duration, isVisible]);

  return;
};
