import { Page } from 'Components/Page';
import backgroundImage from 'Images/screen-end.svg';
import { colors } from '../../Constants/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStores } from 'Hooks/useStores';
import { observer } from 'mobx-react';
import { useApi } from 'Hooks/useApi';
import { Button } from 'Components/Basic/Button';
import { Card } from 'Components/Basic/Card';
import { useSoundFx } from 'Hooks/useSoundFx';
import clickSfx from 'Sounds/click.aac';

export const ModuleFinalView = observer(({ loading, setLoading }) => {
  const { gameStore } = useStores();

  const { play } = useSoundFx(clickSfx, { volume: 1 });

  const { resetUserGameState, getUserGameState } = useApi();

  const [{ maxScore, score, module }, setMyScore] = useState([]);

  const { getMyScoring } = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await getMyScoring();
        const myScore = data.find(({ module }) => module === gameStore.currentModule);
        setMyScore(myScore);
        setLoading(false);
      } catch (e) {
        console.error('Cannot fetch user scores: ' + e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const userGameState = await getUserGameState();
        gameStore.setUserGameState(userGameState);
      } catch (e) {
        console.error('Cannot fetch user game state: ' + e);
      }
    })();
  });

  const resetModuleProgress = async (moduleId) => {
    play();
    gameStore.resetModule(moduleId);
    await resetUserGameState(gameStore.currentModule);
  };

  const goToMap = async () => {
    play();
    gameStore.resetGameState();
  };

  return (
    <Page align="center" backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      {!loading && (
        <Card maxWidth={650} overflow="unset" size="small" open={true}>
          {!!(score && maxScore && score / maxScore > 0.5) ? (
            <ContentSuccess goToMap={goToMap} module={module} score={score} maxScore={maxScore} />
          ) : (
            <ContentFailure goToMap={goToMap} module={module} resetModuleProgress={resetModuleProgress} />
          )}
        </Card>
      )}
    </Page>
  );
});

const MODULES_COUNT = {
  m1: 'Pierwszy',
  m2: 'Drugi',
  m3: 'Trzeci',
  m4: 'Czwarty',
  m5: 'Piąty',
  m6: 'Szósty',
  m7: 'Siódmy'
}

const ContentSuccess = ({ score, maxScore, goToMap, module }) => (
  <ContentWrapper>
    <HeaderLabel paddingLeft={24} success text={'Sukces!'} />
    <ContentText>Gratulacje! {MODULES_COUNT[module]} moduł zaliczony!</ContentText>
    <ContentText>Twój wynik to {((score / maxScore) * 100).toFixed(0)}% możliwych do zdobycia punktów.</ContentText>
    <ContentText>Na Planetę X09 wrócisz w kolejnej części historii!</ContentText>
    <Buttons>
      <ContentButton marginTop={16} onClick={goToMap}>
        Powrót do mapy
      </ContentButton>
    </Buttons>
  </ContentWrapper>
);

const HeaderLabel = ({ success, text, paddingLeft }) => (
  <HeaderLabelContent>
    <svg viewBox="17.894 13.563 211.441 66" width="216.441" height="69">
      <g id="m2-label" transform="matrix(1, 0, 0, 1, -1085.964355, -358.188873)">
        <path
          id="m2-label-bg"
          fill="#FFF"
          stroke="#000F80"
          strokeWidth="2"
          strokeMiterlimit="10"
          d="M 1289.979 429.072 L 1141.829 429.072 L 1141.829 380.431 L 1289.979 380.431 C 1303.409 380.431 1314.299 391.323 1314.299 404.751 L 1314.299 404.751 C 1314.299 418.181 1303.407 429.072 1289.979 429.072 Z"
        ></path>
      </g>
      <g
        id="m2-target"
        stroke="#000F80"
        strokeWidth="2"
        strokeMiterlimit="10"
        transform="matrix(1, 0, 0, 1, -1140.505371, -342.667816)"
      >
        <circle id="m2-outer" fill="#FFF" cx="1192.399" cy="389.231" r="33"></circle>
        <circle id="m2-inner" fill={success ? '#38E0B6' : colors.red} cx="1192.399" cy="389.231" r="20.53"></circle>
      </g>
    </svg>
    <LabelText paddingLeft={paddingLeft}>{text}</LabelText>
  </HeaderLabelContent>
);

const ContentFailure = ({ resetModuleProgress, module, goToMap }) => (
  <ContentWrapper>
    <HeaderLabel text={'Niepowodzenie'} />
    <ContentText>
      Niestety nie udało Ci się zdobyć wymaganej liczby punktów do ukończenia tego modułu. Musisz zdobyć przynajmniej
      50% punktów możliwych do zdobycia. Spróbuj jeszcze raz! Zachęcamy do zapoznania się z dokumentami i instrukcjami
      zamieszczony w aktówce. One bardzo pomogą Ci podjąć dobre decyzje. Powodzenia!
    </ContentText>
    <Buttons>
      <ContentButton marginTop={16} onClick={() => resetModuleProgress(module)}>
        Rozpocznij moduł
      </ContentButton>
      <ContentButton marginTop={16} onClick={goToMap}>
        Powrót do mapy
      </ContentButton>
    </Buttons>
  </ContentWrapper>
);

const HeaderLabelContent = styled.div`
  position: absolute;
  left: -65px;
  top: -90px;
`;

const LabelText = styled.div`
  top: 26%;
  position: absolute;
  left: 37%;
  font-size: 15px;
  font-weight: 800;
  padding-left: ${(props) => props.paddingLeft}px;
`;

const ContentButton = styled(Button)`
  &&& {
    padding: 8px 32px;
    margin: 0 16px;
    min-width: unset;
    font-weight: bold;
  }
`;

const Buttons = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -80px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 25px 0;
  line-height: 30px;
`;

const ContentText = styled.div`
  font-size: 1.6rem;
  text-align: center;
`;
