import { useState } from 'react'
import { Page } from 'Components/Page';
import { colors } from 'Constants/colors';
import backgroundImage from 'Images/login-background.png';
import { ContentWrapper, Message } from './Login';
import { Card } from 'Components/Basic/Card';
import firebase from 'firebase';
import { useStores } from 'Hooks/useStores';
import { Button } from 'Components/Basic/Button';
import { routes } from 'Constants/routes';
import styled from 'styled-components';


export const ConfirmEmail = () => {
  const { userStore } = useStores();
  const [sent, setSent] = useState(false);

  const goBack = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        userStore.setUser(null);
        window.location.href = routes.LOGIN
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const sendAgain = () => {
    firebase
      .auth()
      .currentUser
      .sendEmailVerification()
      .then(function() {
        setSent('Email został wysłany ponownie')
      }).catch(function(error) {
        console.log('Błąd')
      });
  }

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <ContentWrapper>
        <Card marginBottom={64} maxWidth={400}>
          <TextHeader>Pomyślnie zarejestrowano!</TextHeader>
          <TextWrapper>
            Prosimy o sprawdzenie swojej skrzynki mailowej i potwierdzenie podanego adresu email.
          </TextWrapper>
            {sent && <Message>{typeof sent === 'string' ? sent : ''}</Message>}
          <ButtonWrapper>
            <Button onClick={sendAgain}>Wyślij ponownie</Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={goBack}>Powrót do logowania</Button>
          </ButtonWrapper>
        </Card>
      </ContentWrapper>
    </Page>
  )
}

const ButtonWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
`;

const TextHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  padding: 8px 16px;
  width: 100%;
`;

const TextWrapper = styled.div`
  font-size: 1.3rem;
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 16px;
`;
