import React from 'react';
import { TextBox } from './TextBox';
import { motion, useCycle } from 'framer-motion';
import clickSfx from 'Sounds/click.aac';
import { useSoundFx } from 'Hooks/useSoundFx';

const optionVariants = {
  normal: {
    scale: 1,
    transition: { duration: 0.2 },
  },
  hover: {
    scale: 1.03,
    transition: { duration: 0.5 },
  },
};

export const Option = ({ data, changeSlide }) => {
  const { text, nextId } = data;
  const [cycle, cycleAnimation] = useCycle('normal', 'hover');
  const { play } = useSoundFx(clickSfx, { volume: 1 });

  const onOptionClick = () => {
    play();
    changeSlide(data);
  };

  return (
    <motion.div
      variants={optionVariants}
      animate={cycle}
      onHoverStart={() => cycleAnimation()}
      onHoverEnd={() => cycleAnimation()}
    >
      <TextBox
        minWidth={'100%'}
        hover
        margin={'0 32px 4px 0'}
        onClick={!data.taken && onOptionClick}
        bold
        isSmall
        disabled={data.taken}
        cursor={data.taken ? 'not-allowed' : 'pointer'}
      >
        {text}
        {/*(id:{nextId})*/}
      </TextBox>
    </motion.div>
  );
};

export default Option;
