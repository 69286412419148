export const modules = {
  m1: 'm1',
  m2: 'm2',
  m3: 'm3',
  m4: 'm4',
  m5: 'm5',
  m6: 'm6',
  m7: 'm7',
}

export const MAP_MODULE_NAMES = {
  [modules.m1]: 'Moduł 1',
  [modules.m2]: 'Moduł 2',
  [modules.m3]: 'Moduł 3',
  [modules.m4]: 'Moduł 4',
  [modules.m5]: 'Moduł 5',
  [modules.m6]: 'Moduł 6',
  [modules.m7]: 'Moduł 7',
};

export const MAP_DIRECTORIES = {
  [modules.m1]: 'module1',
  [modules.m2]: 'module2',
  [modules.m3]: 'module3',
  [modules.m4]: 'module4',
  [modules.m5]: 'module5',
  [modules.m6]: 'module6',
  [modules.m7]: 'module7',
};
