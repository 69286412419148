import { colors } from 'Constants/colors';
import { Table as SemanticTable } from 'semantic-ui-react';
import styled from 'styled-components';

export const Table = styled(SemanticTable)`
  margin-top: 32px;

  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '')};
  &&& th,
  &&& tr {
    color: ${colors.royalBlue2};
  }
`;
