import styled from 'styled-components';
import { Button as SemanticButton } from 'semantic-ui-react';
import { colors } from '../Constants/colors';
import { withRouter } from 'react-router-dom';


const ButtonText = (props) => {
  const {
    history,
    to,
    ...rest
  } = props
  return <StyledButton {...rest} onClick={() => history.push(to)}/>
}

export default withRouter(ButtonText)

const StyledButton = styled(SemanticButton)`
  &&&.button {
    background: none;
    color: ${colors.royalBlue1};
    border: 0;
    border-radius: 0;
    transition: background-color 0.4s, color 0.4s;
  }
  &&&.button:hover {
    text-decoration: underline;
  }
`