import React, { useEffect } from 'react';
import styled from 'styled-components';
import { modules } from 'Constants/modules';
import { useStores } from 'Hooks/useStores';
import { observer } from 'mobx-react';
import Map from '../SVG/Map';
import openSfx from 'Sounds/open.mp3';
import { useSoundFx } from 'Hooks/useSoundFx';

export const ModuleSelect = observer(({ setLoading }) => {
  const { gameStore, configStore } = useStores();
  const { play } = useSoundFx(openSfx, { volume: 1 });

  const handleModuleSelect = (e) => {
    setLoading(true);
    const moduleMapId = e.target.id ? e.target.id.substring(0, 2) : '';
    const moduleId = Object.keys(modules).find((key) => modules[key] === moduleMapId);
    gameStore.setCurrentModule(moduleId);
    gameStore.loadModuleState();
    if (gameStore.userGameState?.gamePaths) {
      play();
      configStore.setPlayBackgroundSound(false);
      if (gameStore.userGameState.gamePaths[moduleId]?.finished) {
        gameStore.setCurrentModuleFinished();
      }
    }
  };

  useEffect(() => {
    configStore.setPlayBackgroundSound(true);
  }, []);

  return (
    <MapWrapper>
      <Map handleClick={handleModuleSelect} activeModules={gameStore.activeModules} />
    </MapWrapper>
  );
});

export default ModuleSelect;

const MapWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #0d2133, #2e0e3a);

  svg {
    height: 100%;
  }
`;
