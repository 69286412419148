import { useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useSoundFx } from './useSoundFx';
import backgroundSfx from 'Sounds/background.aac';

export const useBackgroundSound = (isPlaying) => {
  const isVisible = usePageVisibility();

  const { play, exposedData } = useSoundFx(backgroundSfx, { loop: true, volume: 0.05 });

  useEffect(() => {
    if (isPlaying && exposedData.duration && isVisible) {
      play();
    }
    if (!isPlaying || !isVisible) {
      exposedData.stop();
    }
    return () => exposedData.stop();
  }, [isVisible, isPlaying, exposedData.duration, play]);
};
