import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Header, List, ListItem } from 'semantic-ui-react';
import { Modal, ModalStyled } from 'Components/Basic/Modal';
import { useStores } from 'Hooks/useStores';
import { useFirebaseStorage } from 'Hooks/useFirebaseStorage';
import { SinglePagePdf } from 'Components/FileView/SinglePagePdf';

export const DocumentModal = observer(({ open, setOpen }) => {
  const { documentStore } = useStores();
  const { getDocumentByRef } = useFirebaseStorage();
  const [view, setView] = useState(null);
  const [name, setName] = useState('');
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const onDocumentClick = (ref) => {
    getDocumentByRef(ref).then((url) => {
      setView(url);
      setName(ref.name.split('.').shift());
      setViewModalOpen(true);
    });
  };

  return (
    <>
      <Modal size='small' onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={!viewModalOpen && open}>
        <ModalStyled.Description>
          <List selection animated size='big'>
            {documentStore.documents &&
            documentStore.documents.length > 0 ?
            documentStore.documents.map((ref, idx) => (
              <ListItem key={idx} onClick={() => onDocumentClick(ref)}>
                <List.Icon name='file' />
                {ref.name.split('.').shift()}
              </ListItem>
            )) : <Header>Ten moduł nie posiada dodatkowych plików do wglądu.</Header>}
          </List>
        </ModalStyled.Description>
      </Modal>
      <Modal scrolling size='large' onClose={() => setViewModalOpen(false)} open={viewModalOpen}>
        <ModalStyled.Header>{name}</ModalStyled.Header>
        <SinglePagePdf pdfUrl={view} />
      </Modal>
    </>
  );
});
