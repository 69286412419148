import { Page } from 'Components/Page';
import { useApi } from 'Hooks/useApi';
import { Dimmer, Dropdown, Loader, Pagination as SemanticPagination } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import backgroundImage from 'Images/login-background.png';
import { Card } from 'Components/Basic/Card';
import { Table } from 'Components/Basic/Table';
import { colors } from '../Constants/colors';

const moduleFilterOptions = [
  { key: 'all', text: 'Podsumowanie', value: 'all' },
  { key: 'm1', text: 'Moduł 1', value: 'm1' },
  { key: 'm2', text: 'Moduł 2', value: 'm2' },
  { key: 'm3', text: 'Moduł 3', value: 'm3' },
  { key: 'm4', text: 'Moduł 4', value: 'm4' },
  { key: 'm5', text: 'Moduł 5', value: 'm5' },
  { key: 'm6', text: 'Moduł 6', value: 'm6' },
  { key: 'm7', text: 'Moduł 7', value: 'm7' },
];

export const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getScoring } = useApi();

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [myPos, setMyPos] = useState(-1);
  const [myScore, setMyScore] = useState('');

  const [dropdownOption, setDropdownOption] = useState(moduleFilterOptions[0]);

  useEffect(() => {
    fetchUsers({ page, pageSize, moduleId: dropdownOption.value });
  }, [page, dropdownOption, pageSize]);

  const fetchUsers = async (params) => {
    try {
      setLoading(true);
      const response = await getScoring(params);
      setTotalPages(response?.totalPages || 1);
      setUsers(response?.users || []);
      setMyPos(response?.myPos || -1);
      setMyScore(response?.score || 0);
      setPage(response?.page || 1);
    } catch (e) {
      console.error(e);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (e, { activePage }) =>
    fetchUsers({ page: activePage, pageSize, moduleId: dropdownOption.value });

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <Content>
        <ScoringCard maxWidth>
          <Dimmer inverted active={!!loading}>
            <Loader />
          </Dimmer>
          <PaginationWrapper>
            <h3>Tablica wyników</h3>
          </PaginationWrapper>
          <ItemWrapper>
            <div style={{ marginRight: '10px' }}>Filtr</div>
            <Dropdown
              onChange={(e, { value }) => {
                setPage(1);
                setDropdownOption(moduleFilterOptions.find((ele) => ele.value === value));
              }}
              labeled
              options={moduleFilterOptions}
              search
              placeholder="Wybierz moduł"
              text={dropdownOption.text}
            />
          </ItemWrapper>
          <ItemWrapper>
            <div style={{ marginRight: '10px' }}>Twój wynik: </div>
            <div>{myScore}</div>
          </ItemWrapper>
          <TableWrapper>
            <Table basic="very" celled style={{ flexGrow: 1, marginTop: '16px' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Pozycja</Table.HeaderCell>
                  <Table.HeaderCell>Nazwa użytkownika</Table.HeaderCell>
                  <Table.HeaderCell>Wynik</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users &&
                  users.map((user, index) => (
                    <Row
                      key={user.username + index}
                      active={myPos === pageSize * (page - 1) + index + 1 && user.username !== 'unknown'}
                    >
                      <Table.Cell width={2} textAlign={'center'}>
                        {pageSize * (page - 1) + index + 1}
                      </Table.Cell>
                      <Table.Cell>{user.username}</Table.Cell>
                      <Table.Cell>{user.score}</Table.Cell>
                    </Row>
                  ))}
              </Table.Body>
            </Table>
            <PaginationWrapper>
              {!loading && (
                <Pagination
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  onPageChange={handlePaginationChange}
                  defaultActivePage={page}
                  activepage={page}
                  totalPages={totalPages}
                />
              )}
            </PaginationWrapper>
          </TableWrapper>
        </ScoringCard>
      </Content>
    </Page>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Row = styled(Table.Row)`
  &&&.active {
    font-weight: 700;
    color: white !important;
    background: ${colors.orange1} !important;
  }
`;

const Pagination = styled(SemanticPagination)`
  &&& a {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const ItemWrapper = styled.div`
  margin: 10px 0;
  display: flex;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ScoringCard = styled(Card)`
  min-height: 638px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
