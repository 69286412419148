import React from 'react';
import { motion } from 'framer-motion';
import { Modal as SemanticModal, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from 'Constants/colors';

export const Modal = ({ scrolling, dimmer, size, children, onClose, onOpen, open }) => {
  return (
    <ModalStyled
      inverted
      size={size}
      dimmer={dimmer}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      mountNode={document.getElementById('mountModal')}
    >
      {onClose && (
        <CloseButton onClick={onClose}>
          <motion.div whileHover={{ rotate: 90 }} transition={{ duration: 0.2 }}>
            <Icon name={'remove'} />
          </motion.div>
        </CloseButton>
      )}
      <Content scrolling={scrolling}>{children}</Content>
    </ModalStyled>
  );
};

const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 8px !important;
  top: -20px;
  right: -20px;
  font-size: 24px;
  background-color: ${colors.red};
  border: 2px solid ${colors.royalBlue2};
  box-shadow: 3px 3px ${colors.royalBlue2};
  cursor: pointer;

  i {
    margin: 0;
  }
`;

export const ModalStyled = styled(SemanticModal)`
  &&& {
    position: relative;
    border-radius: 30px;
    border: 3px solid ${colors.royalBlue2};
    box-shadow: 3px 3px ${colors.royalBlue2};
  }
`;

const Content = styled(ModalStyled.Content)`
  &&& {
    overflow: auto;
    border-radius: 30px;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
  }
`;
