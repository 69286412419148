import { Page } from 'Components/Page';
import React, { useState } from 'react';
import { useStores } from 'Hooks/useStores';
import { Popup, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useApi } from 'Hooks/useApi';
import ButtonLink from '../Components/ButtonLink';
import { routes } from '../Constants/routes';
import { Card } from 'Components/Basic/Card';
import { Button } from 'Components/Basic/Button';
import { Input } from 'Components/Basic/Input';

import backgroundImage from 'Images/login-background.png';
import titleImage from 'Images/login-title.png';
import { colors } from '../Constants/colors';
import firebase from 'firebase/app';

const InputWrapper = styled.div`
  padding: 8px 16px;
`;

const TitleImage = styled.img`
  width: 650px;
  height: auto;
  max-width: 90%;
  margin-bottom: 50px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Message = styled.p`
  color: ${(props) => (props.success ? 'green' : 'red')};
  text-align: center;
  width: 100%;
  padding: 0 16px;
`;

const Form = styled.form``;

const passwordMinLength = 14;
const usernameMinLength = 3;

export const Registration = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);

  const { userStore } = useStores();
  const { registerUser } = useApi();

  const onRegister = async (e) => {
    e.preventDefault();
    setError(false)
    const valid = validateAllFields();
    if (valid) {
      const result = await registerUser({ email, username, password });
      if (result.status) {
        setError(result.status);
      }
      if (result.message) {
        firebase.auth().signInWithCustomToken(result.message).then(user => {
          user.user.sendEmailVerification()
            .then(() => setMessage(result.message))
            .catch(() => setError("Błąd podczas wysyłania email z potwierdzeniem"))
        })
        .catch(() => setError("Błąd podczas zakładania konta, prosimy o powiadomienie administratora."))
      }
    } else {
      setError('Jedno z pól nie spełnia wymogów walidacji');
    }
  };

  const onEmailChange = (e) => {
    setError(false);
    setEmail(e.target.value.toLowerCase());
  };

  const onUsernameChange = (e) => {
    setError(false);
    setUsername(e.target.value.toLowerCase());
  };

  const onPasswordChange = (e) => {
    setError(false);
    setPassword(e.target.value);
  };

  const isPasswordValid = () => {
    return isMinLengthOk() && isSpecialSignOk();
  };

  const isMinLengthOk = () => {
    return password.length >= passwordMinLength
  }

  const isSpecialSignOk = () => {
    const regex = new RegExp('(?=.*[!@#$%^&*])')
    return regex.test(password)
  }

  const isEmailValid = () => {
    return email.length >= 4 && email.includes('@');
  };

  const isUsernameValid = () => {
    return username.length >= usernameMinLength;
  };

  const validateAllFields = () => {
    return isEmailValid() && isPasswordValid() && isUsernameValid();
  };

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <ContentWrapper>
        <TitleImage src={titleImage} alt="INEA" />
        <Card marginBottom={64} maxWidth={400}>
          <Form onSubmit={onRegister}>
            <InputWrapper>
              <Input placeholder="Email" type="email" autocomplete="none" value={email} onChange={onEmailChange} />
            </InputWrapper>
            <InputWrapper>
              <Input
                placeholder="Hasło"
                type="password"
                autocomplete="new-password"
                value={password}
                onChange={onPasswordChange}
              />
            </InputWrapper>
            <InfoInput
              name="Username"
              value={username}
              iconName="info circle"
              infoText="Nazwa użytkownika widoczna dla innych graczy"
              onChange={onUsernameChange}
            />
            {!error && !message && <RegistrationConstraints lenPassed={isMinLengthOk()} specialPassed={isSpecialSignOk()} />}
            <MessageWrapper>
              {error && <Message>{error}</Message>}
              {message && <Message success>{message}</Message>}
            </MessageWrapper>
            <InputWrapper>
              <Button disabled={!isPasswordValid()} type="submit">
                Rejestruj
              </Button>
            </InputWrapper>
          </Form>
          <LoginWrapper>
            <ButtonLink fluid to={routes.LOGIN} onClick={(e) => e.preventDefault()}>
              Logowanie
            </ButtonLink>
          </LoginWrapper>
        </Card>
      </ContentWrapper>
      {!!userStore.user && <Redirect to="/" />}
    </Page>
  );
};

export const RegistrationConstraints = ({ lenPassed, specialPassed }) => {
  return (
    <>
      <Constraint passed={lenPassed}>
        <div>
          <Icon name={lenPassed ? 'check' : 'exclamation'} size="small" />
          Hasło minimum {passwordMinLength} znaków
        </div>
      </Constraint>
      <Constraint passed={specialPassed}>
        <div>
          <Icon name={specialPassed ? 'check' : 'exclamation'} size="small" />
          Przynajmniej jeden znak specjalny
        </div>
      </Constraint>
    </>
  );
};

export const InfoInput = ({ name, value, onChange, infoText, iconName }) => {
  return (
    <InfoInputWrapper>
      <Input placeholder={name} value={value} onChange={onChange} />
      <Popup
        pinned
        inverted
        content={infoText}
        trigger={<Icon style={{ position: 'absolute', right: '22px', top: '22px' }} circle name={iconName} />}
      />
    </InfoInputWrapper>
  );
};

const InfoInputWrapper = styled.div`
  padding: 8px 16px;
  position: relative;
`;

const LoginWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
`;

const MessageWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  font-size: 14px;
`;

const Constraint = styled.div`
  padding: 3px 16px;
  width: 100%;
  font-size: 14px;
  color: ${(props) => (props.passed ? 'green' : 'gray')};
`;
