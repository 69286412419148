import firebase from 'firebase/app';
import { Page } from 'Components/Page';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks/useStores';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useApi } from 'Hooks/useApi';
import { getNow } from 'Helpers/time';
import ButtonLink from '../Components/ButtonLink';
import { routes } from '../Constants/routes';
import backgroundImage from 'Images/login-background.png';
import titleImage from 'Images/login-title.png';
import { Card } from 'Components/Basic/Card';
import { Button } from 'Components/Basic/Button';
import { Input } from 'Components/Basic/Input';
import { colors } from '../Constants/colors';
import ButtonText from '../Components/ButtonText';


const InputWrapper = styled.div`
  padding: 8px 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Message = styled.p`
  color: ${props => props.error ? 'red' : 'green'};
  text-align: center;
  width: 100%;
  padding: 8xp 16px;
`;

export const TitleImage = styled.img`
  width: 650px;
  max-width: 90%;
  height: auto;
  margin-bottom: 50px;
`;

const RegistrationWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.form``;

const defaultErrorMessage = "Podano nieprawidłowy adres email lub hasło"

export const Login = observer(() => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const { userStore } = useStores();
  const { updateUserActions } = useApi();

  const onLogin = e => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0) {
      console.log('send')
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(() => {
          setError(true);
        })
    } else {
      setError('Proszę wprowadzić dane logowania')
    }
  };

  useEffect(() => {
    updateUserActions('User logged in on ' + getNow());
  }, [updateUserActions, userStore.user]);

  useEffect(() => {
    setError(false)
  }, [email, password])

  return (
    <Page backgroundColor={colors.royalBlue2} backgroundImage={backgroundImage}>
      <ContentWrapper>
        <TitleImage src={titleImage} alt="INEA" />
        <Card marginBottom={64} maxWidth={400}>
          <Form onSubmit={onLogin}>
            <InputWrapper>
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                placeholder="Hasło"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputWrapper>
            {error && <Message error>{typeof error === 'string' ? error : defaultErrorMessage}</Message>}
            <InputWrapper>
              <Button bold type="submit">Login</Button>
            </InputWrapper>
          </Form>
          <RegistrationWrapper>
            <ButtonLink bold fluid to={routes.REGISTRATION} onClick={(e) => e.preventDefault()}>
              Rejestracja
            </ButtonLink>
          </RegistrationWrapper>
          <ButtonTextWrapper>
            <ButtonText to={routes.RESET_PASSWORD}>Nie pamiętam hasła</ButtonText>
          </ButtonTextWrapper>
        </Card>
      </ContentWrapper>
      {!!userStore.user && <Redirect to="/" />}
    </Page>
  );
});


