import { Icon } from 'semantic-ui-react';
import { Button } from '../Basic/Button';
import { useState } from 'react';
import { colors } from 'Constants/colors';

export const ButtonWithConfirmation = ({ icon, onClick }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (!showConfirmation) {
    return (
      <Button onClick={() => setShowConfirmation(!showConfirmation)}>
        <Icon name={icon} />
      </Button>
    );
  } else {
    return (
      <>
        <Button color={colors.red} isSmall onClick={() => setShowConfirmation(!showConfirmation)}>
          <Icon name='cancel' />
        </Button>
        <Button color={colors.green} isSmall onClick={onClick}>
          <Icon name='check' />
        </Button>
      </>
    );
  }
};
