import { UserStore } from './UserStore';
import { GameStore } from './GameStore';
import { DocumentStore } from './DocumentStore';
import { ConfigStore } from './ConfigStore';

export class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.gameStore = new GameStore(this);
    this.documentStore = new DocumentStore(this);
    this.configStore = new ConfigStore(this);
  }
}

export const rootStore = new RootStore();
