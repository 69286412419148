import { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { IconWrapper } from './Config';
import useFullscreenStatus from '../../Hooks/useFullScreenStatus';

export const Fullscreen = ({ configStore }) => {
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(configStore.getFullScreenComponentRef());
  } catch (e) {
    errorMessage = 'Fullscreen not supported';
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  useEffect(() => {
    configStore.setIsFullscreen(isFullscreen);
  }, [isFullscreen]);

  const handleExitFullscreen = () => document.exitFullscreen();

  return (
    <IconWrapper>
      {!errorMessage && (
        <Icon onClick={isFullscreen ? handleExitFullscreen : setIsFullscreen} size='big'
              name={isFullscreen ? 'compress' : 'expand arrows alternate'} />
      )}
    </IconWrapper>
  );
};
