import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const prodConfig = {
  apiKey: 'AIzaSyDYcwfj9NuctFYFbJ_ut7i814vQaiX6Dy0',
  authDomain: 'gracompliance.pl',
  databaseURL: 'https://argon-system-2020.firebaseio.com',
  projectId: 'argon-system-2020',
  storageBucket: 'argon-system-2020.appspot.com',
  messagingSenderId: '219949857756',
  appId: '1:219949857756:web:f71d40e085cb3ccb64ed19',
};

const testConfig = {
  apiKey: "AIzaSyABZODP-uyCDJhhimRo_zzZ8d-JplpFq64",
  authDomain: "tanzania-2021.firebaseapp.com",
  projectId: "tanzania-2021",
  storageBucket: "tanzania-2021.appspot.com",
  messagingSenderId: "260615972597",
  appId: "1:260615972597:web:f9c775e2cf77da9b8147bc",
  measurementId: "G-CJG179QPSK"
}

export const useFirebase = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase.apps.length) {
      const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : testConfig
      firebase.initializeApp(config);
      setLoading(false)
    }
  }, []);

  return { loading }
};
