import React from 'react';
import styled from 'styled-components';
import { shape, string, any, array, func } from 'prop-types';
import ErrorBox from './ErrorBox';
import { ChatWidget } from './ChatWidget';
import { useGameSound } from 'Hooks/useGameSound';
import { observer } from 'mobx-react';

export const Slide = observer(({ data, path, images, changeSlide }) => {
  const { id, imageId: currentImageId, soundId, secondarySoundId, loopSound, text, options, npcName } = data || {};

  useGameSound(soundId, loopSound);
  useGameSound(secondarySoundId, false);

  const optionsWithPath = options?.map((option) => {
    const prevPathPosition = path.indexOf(option.nextId);
    const pathAlreadyTaken = prevPathPosition > 0 ? id === path[prevPathPosition - 1] : false;
    return pathAlreadyTaken ? { taken: true, ...option } : option;
  });

  return options ? (
    <Container>
      {images.map(({ src, imageId }, index) => (
        <Image src={src} key={index} visible={currentImageId === imageId} />
      ))}
      <ChatWidget id={id} npcName={npcName} text={text} options={optionsWithPath} changeSlide={changeSlide} />
    </Container>
  ) : (
    <ErrorBox />
  );
});

Slide.propTypes = {
  images: array,
  data: shape({
    id: any,
    text: string,
    options: array,
  }),
  changeSlide: func,
};

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
