import { useStores } from '../../Hooks/useStores';
import { Fullscreen } from './Fullscreen';
import styled from 'styled-components';
import { SoundConfig } from './SoundConfig';
import { InstructionsModal } from './InstrictionsModal';

export const Config = () => {
  const { configStore } = useStores();

  return (
    <IconOptions>
      <InstructionsModal/>
      <SoundConfig configStore={configStore}/>
      <Fullscreen configStore={configStore}/>
    </IconOptions>
  )
}

const IconOptions = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
`

export const IconWrapper = styled.div`
  margin: 0 8px;
  color: white;
  cursor: pointer;
  i {
    transition: all 0.15s ease-in-out;
  }
  i:hover {
    transform: scale(1.2);
  }
`;

