import { CSVLink } from 'react-csv';
import { array } from 'prop-types';
import { Icon } from 'semantic-ui-react';

export const ExportEmails = ({ users }) => {
  return (
    <>
      <div>
        <CSVLink
          filename={'export_pierwszy_modul_nie_skonczony.csv'}
          headers={[{ key: 'email', label: 'Email użytkownika' }]}
          data={users
            .filter(
              (user) =>
                !user.games?.m1?.finishedDate || (user.games?.m1?.score && user.games?.m1?.score <= 16)
            )
            .map((user) => ({
              email: user.email,
            }))}
        >
          Eksportuj adresy email osób, które nie ukończyły pierwszego modułu
          <Icon name={'save'} style={{ marginLeft: '5px' }} />
        </CSVLink>
      </div>
      <div>
        <CSVLink
          filename={'export_pierwszy_modul_ukonczony.csv'}
          headers={[{ key: 'email', label: 'Email użytkownika' }]}
          data={users
            .filter(
              (user) =>
                user.games?.m1?.finishedDate && user.games?.m1?.score && user.games?.m1?.score > 16           
            )
            .map((user) => ({
              email: user.email,
            }))}
        >
          Eksportuj adresy email osób, które ukończyły pierwszy moduł
          <Icon name={'save'} style={{ marginLeft: '5px' }} />
        </CSVLink>
      </div>
    </>
  );
};

ExportEmails.propTypes = {
  users: array,
};
