import { useState, useEffect } from 'react'
import styled from 'styled-components';
import Option from './Option';
import { useRef, useLayoutEffect } from 'react';

export const Options = ({ id, options, changeSlide }) => {
  const ref = useRef();
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(false)
    setTimeout(() => {
      setActive(true)
    }, 1000);
  }, [id])

  useLayoutEffect(() => {
    if (ref?.current) {
      setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }), 0);
    }
  }, [ref.current, options]);

  return options ? (
    <OptionWrapper ref={ref} active={active}>
      {options.map((ele, index) => (
        <Option key={index} data={ele} changeSlide={changeSlide} />
      ))}
    </OptionWrapper>
  ) : null;
};

const OptionWrapper = styled.div`
  left: 15%;
  right: 5%;
  position: absolute;
  padding-bottom: 16px;
  top: calc(100% - 16px);
  ${(props) => (!props.active && 'pointer-events: none;') }
`;
