import React, { useEffect, useRef } from 'react';
import { MAP_MODULE_NAMES, modules } from 'Constants/modules';
import { motion } from 'framer-motion';

function Map({ handleClick, activeModules, props }) {
  const activeModuleNames = activeModules.map((module) => module.name);

  const moduleGroups = {
    [modules.m1]: useRef(null),
    [modules.m2]: useRef(null),
    [modules.m3]: useRef(null),
    [modules.m4]: useRef(null),
    [modules.m5]: useRef(null),
    [modules.m6]: useRef(null),
    [modules.m7]: useRef(null)
  }

  const moduleLockGroups = {
    [modules.m1]: useRef(null),
    [modules.m2]: useRef(null),
    [modules.m3]: useRef(null),
    [modules.m4]: useRef(null),
    [modules.m5]: useRef(null),
    [modules.m6]: useRef(null),
    [modules.m7]: useRef(null)
  }

  const pinRefs = {
    [modules.m1]: useRef(null),
    [modules.m2]: useRef(null),
    [modules.m3]: useRef(null),
    [modules.m4]: useRef(null),
    [modules.m5]: useRef(null),
    [modules.m6]: useRef(null),
    [modules.m7]: useRef(null)
  }

  const setModulesVisibility = () => {
    // console.log('moduleGroups', moduleGroups)
    // console.log('moduleLockGroups',moduleLockGroups)
    Object.keys(modules).forEach(ele => {
      if (!activeModuleNames.includes(ele)) {
        moduleGroups[ele].current.style.display = "none"
        moduleLockGroups[ele].current.style.display = "inline"
      } else {
        moduleGroups[ele].current.style.display = "inline"
        moduleLockGroups[ele].current.style.display = "none"
      }
    })
  }

  useEffect(() => {
    setModulesVisibility()
  }, [moduleGroups, moduleLockGroups])


  const setPinsVisibility = () => {
    Object.keys(modules).forEach(ele => {
      // if (activeModuleNames.includes(ele)) {
        pinRefs[ele].current.style.display = "none"
      // }
    })
  }

  useEffect(() => {
    setPinsVisibility()
  }, [pinRefs])

  // const onActiveModuleHover = (e) => {
  //   const moduleId = getModuleIdFromEvent(e)
  //   // setPinVisibility(moduleId, true)
  // }
  //
  // const onActiveModuleOut = (e) => {
  //   const moduleId = getModuleIdFromEvent(e)
  //   // setPinVisibility(moduleId, false)
  // }
  //
  // const setPinVisibility = (moduleId, visible) => {
  //   pinRefs[moduleId].current.style.display = visible ? "block" : "none"
  // }
  //
  // const getModuleIdFromEvent = (e) => {
  //   return e.target.id ? e.target.id.substring(0,2) : ""
  // }

  //https://stackoverflow.com/questions/57125263/animate-children-when-hover-at-parent-with-framer-motion

  const hoverModule = {
      scale: 1.1
  }


  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      width={2230}
      height={1080}
      viewBox="0 0 2230 1080"
      xmlSpace="preserve"
      {...props}
    >
      <g id="bg">
        <path
          fill="#000F80"
          stroke="#000F80"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M-78.33-17.69h2388.908V1102.6H-78.33z"
        />
        <g fill="#2E3EB3">
          <circle cx={833.99} cy={645.52} r={4.56} />
          <circle cx={878.948} cy={834.191} r={16.35} />
          <circle cx={878.948} cy={834.19} r={6.68} />
          <circle cx={975.18} cy={836.8} r={2.69} />
          <g>
            <circle cx={804.78} cy={723.45} r={7.99} />
            <circle cx={804.78} cy={723.45} r={4.46} />
          </g>
        </g>
        <circle fill="#2E3EB3" cx={307.22} cy={529.51} r={6.82} />
        <circle fill="#2E3EB3" cx={304.31} cy={391.38} r={4.56} />
        <circle fill="#2E3EB3" cx={370.158} cy={518.45} r={4.56} />
        <circle fill="#2E3EB3" cx={101.99} cy={467.21} r={4.56} />
        <g fill="#2E3EB3">
          <circle cx={206.96} cy={438.09} r={16.35} />
          <circle cx={206.96} cy={438.09} r={6.68} />
        </g>
        <circle fill="#2E3EB3" cx={228.96} cy={532.53} r={7.22} />
        <circle fill="#2E3EB3" cx={149.43} cy={478.29} r={4.09} />
        <circle fill="#2E3EB3" cx={192.43} cy={360.54} r={2.69} />
        <circle fill="#2E3EB3" cx={105.75} cy={273.49} r={22.56} />
        <circle fill="#0086D4" cx={105.75} cy={273.49} r={12.05} />
        <circle fill="#2E3EB3" cx={382.61} cy={436.15} r={16.35} />
        <circle fill="#2E3EB3" cx={382.61} cy={436.15} r={9.12} />
        <g fill="#2E3EB3">
          <circle cx={45.51} cy={701.79} r={4.56} />
          <circle cx={66.54} cy={906.52} r={2.69} />
        </g>
        <g>
          <circle fill="#2E3EB3" cx={655.118} cy={585.62} r={3.34} />
          <circle fill="#2E3EB3" cx={650.82} cy={518.12} r={2.23} />
          <circle fill="#2E3EB3" cx={685.68} cy={578.902} r={2.23} />
          <circle fill="#2E3EB3" cx={553.44} cy={559.43} r={2.23} />
          <g fill="#2E3EB3">
            <circle cx={604.18} cy={543} r={8} />
            <circle cx={604.18} cy={543} r={3.27} />
          </g>
          <circle fill="#2E3EB3" cx={616.908} cy={588.73} r={3.54} />
          <circle fill="#2E3EB3" cx={576.868} cy={563.86} r={2} />
          <circle fill="#2E3EB3" cx={595.459} cy={505.36} r={1.31} />
          <circle fill="#2E3EB3" cx={690.06} cy={538.39} r={4.47} />
          <g fill="#2E3EB3">
            <circle cx={1000.49} cy={960.84} r={3.34} />
            <circle cx={996.19} cy={893.33} r={2.23} />
            <circle cx={1031.05} cy={954.12} r={2.23} />
            <circle cx={898.81} cy={934.64} r={2.23} />
            <circle cx={949.55} cy={918.211} r={8} />
            <circle cx={949.55} cy={918.21} r={3.27} />
            <circle cx={962.28} cy={963.95} r={3.54} />
            <circle cx={922.24} cy={939.08} r={2} />
            <circle cx={940.83} cy={880.58} r={1.31} />
            <g>
              <circle cx={1035.43} cy={913.6} r={8} />
              <circle cx={1035.43} cy={913.6} r={4.47} />
            </g>
          </g>
          <g fill="#2E3EB3">
            <circle cx={464.11} cy={674.57} r={3.34} />
            <circle cx={468.408} cy={742.08} r={2.23} />
            <circle cx={433.55} cy={681.29} r={2.23} />
            <circle cx={748.118} cy={926.37} r={2.23} />
            <circle cx={565.789} cy={700.77} r={2.229} />
            <circle cx={638.5} cy={919.131} r={4.41} />
            <circle cx={477.82} cy={765.302} r={8} />
            <circle cx={477.82} cy={765.3} r={3.27} />
            <circle cx={399.53} cy={754.93} r={6.62} />
            <circle cx={399.53} cy={754.93} r={2.71} />
            <circle cx={516.448} cy={964.281} r={3.27} />
            <circle cx={350.02} cy={888.31} r={3.27} />
            <circle cx={486.84} cy={723.39} r={3.54} />
            <circle cx={542.36} cy={696.342} r={2} />
            <circle cx={523.78} cy={754.83} r={1.31} />
            <g>
              <circle cx={429.17} cy={721.81} r={3.91} />
              <circle cx={429.17} cy={721.81} r={2.18} />
            </g>
          </g>
          <g fill="#2E3EB3">
            <circle cx={322.57} cy={924.92} r={3.34} />
            <circle cx={373.35} cy={969.612} r={2.23} />
            <circle cx={305.72} cy={951.281} r={2.23} />
            <circle cx={701.448} cy={902.14} r={2.23} />
            <circle cx={413} cy={871.54} r={2.23} />
            <circle cx={663.88} cy={1019.69} r={4.41} />
            <circle cx={396.42} cy={979.382} r={8} />
            <circle cx={396.42} cy={979.38} r={3.27} />
            <circle cx={333.73} cy={1027.4} r={6.62} />
            <circle cx={333.73} cy={1027.4} r={2.71} />
            <circle cx={590.03} cy={981.87} r={3.27} />
            <circle cx={418.63} cy={1045.84} r={3.27} />
            <circle cx={373.17} cy={943.36} r={3.54} />
            <circle cx={393.289} cy={884.98} r={2} />
            <circle cx={421.52} cy={939.48} r={1.311} />
            <g>
              <circle cx={331.27} cy={983.02} r={3.91} />
              <circle cx={331.27} cy={983.02} r={2.18} />
            </g>
          </g>
          <g fill="#2E3EB3">
            <circle cx={776.33} cy={766.57} r={3.34} />
            <circle cx={725.56} cy={721.87} r={2.23} />
            <circle cx={793.19} cy={740.2} r={2.23} />
            <circle cx={685.9} cy={819.94} r={2.229} />
            <circle cx={702.48} cy={712.11} r={8} />
            <circle cx={702.48} cy={712.112} r={3.27} />
            <circle cx={725.74} cy={748.12} r={3.54} />
            <circle cx={727.868} cy={836.98} r={3.54} />
            <circle cx={441.55} cy={934.98} r={3.54} />
            <circle cx={705.61} cy={806.51} r={2} />
            <circle cx={677.39} cy={752} r={1.31} />
            <circle cx={767.63} cy={708.46} r={3.91} />
            <circle cx={767.63} cy={708.46} r={2.18} />
            <circle cx={360.72} cy={756.93} r={3.34} />
            <circle cx={342.26} cy={822.01} r={2.23} />
            <circle cx={329.67} cy={753.08} r={2.23} />
            <circle cx={343.39} cy={847.041} r={8} />
            <circle cx={343.39} cy={847.04} r={3.27} />
            <circle cx={365.868} cy={810.54} r={3.54} />
            <circle cx={427.23} cy={803.552} r={2} />
            <g>
              <circle cx={312.03} cy={789.81} r={3.91} />
              <circle cx={312.03} cy={789.81} r={2.18} />
            </g>
          </g>
          <g fill="#2E3EB3">
            <circle cx={230.76} cy={673.631} r={3.34} />
            <circle cx={212.58} cy={608.47} r={2.23} />
            <circle cx={259.27} cy={660.73} r={2.23} />
            <circle cx={125.86} cy={669.05} r={2.23} />
            <circle cx={172.1} cy={642.47} r={8} />
            <circle cx={172.1} cy={642.47} r={3.27} />
            <circle cx={194.02} cy={684.58} r={3.54} />
            <circle cx={149.698} cy={668.541} r={2} />
            <circle cx={155.77} cy={607.46} r={1.31} />
            <g>
              <circle cx={255.16} cy={620.18} r={8} />
              <circle cx={255.16} cy={620.18} r={4.47} />
            </g>
          </g>
          <g fill="#2E3EB3">
            <circle cx={62.67} cy={887.94} r={3.34} />
            <circle cx={121.6} cy={921.16} r={2.23} />
            <circle cx={51.64} cy={917.22} r={2.23} />
            <circle cx={127.61} cy={787.17} r={2.23} />
            <circle cx={90.28} cy={803.26} r={8} />
            <circle cx={90.28} cy={803.26} r={3.27} />
            <circle cx={105.31} cy={868.97} r={3.54} />
            <circle cx={99.71} cy={837.77} r={2} />
            <circle cx={162.49} cy={881.7} r={1.31} />
            <g>
              <circle cx={83.21} cy={942.99} r={8} />
              <circle cx={83.21} cy={942.99} r={4.47} />
            </g>
          </g>
        </g>
        <g>
          <circle fill="#2E3EB3" cx={338.89} cy={884.25} r={5.92} />
          <circle fill="#2E3EB3" cx={401.49} cy={979.51} r={4.81} />
          <circle fill="#2E3EB3" cx={298.27} cy={922.1} r={3.96} />
          <circle fill="#2E3EB3" cx={517.89} cy={832.51} r={3.96} />
          <circle fill="#2E3EB3" cx={444.42} cy={1012.28} r={17.25} />
          <circle fill="#0086D4" cx={444.42} cy={1012.28} r={7.05} />
          <circle fill="#0086D4" cx={417.68} cy={938.321} r={6.28} />
          <circle fill="#0086D4" cx={478.078} cy={846.88} r={3.55} />
          <circle fill="#2E3EB3" cx={502.48} cy={953.02} r={4.66} />
          <g>
            <circle fill="#2E3EB3" cx={328.15} cy={987.94} r={6.94} />
            <circle fill="#0086D4" cx={328.15} cy={987.94} r={3.87} />
          </g>
          <g>
            <circle fill="#2E3EB3" cx={722.868} cy={782.31} r={5.92} />
            <circle fill="#2E3EB3" cx={700.408} cy={900.22} r={3.96} />
            <circle fill="#2E3EB3" cx={667.38} cy={780.35} r={3.96} />
            <circle fill="#2E3EB3" cx={886.02} cy={872.29} r={3.96} />
            <circle fill="#2E3EB3" cx={791.56} cy={878.091} r={14.2} />
            <circle fill="#0086D4" cx={791.56} cy={878.091} r={5.8} />
            <circle fill="#0086D4" cx={789.908} cy={793.87} r={6.28} />
            <circle fill="#0086D4" cx={847.709} cy={854.31} r={3.55} />
            <circle fill="#2E3EB3" cx={789.908} cy={946.62} r={2.33} />
            <g>
              <circle fill="#2E3EB3" cx={641.948} cy={848.04} r={14.2} />
              <circle fill="#0086D4" cx={641.948} cy={848.04} r={7.93} />
            </g>
            <g>
              <circle fill="#2E3EB3" cx={272.82} cy={102.74} r={3.96} />
              <circle fill="#2E3EB3" cx={458.44} cy={74.81} r={3.96} />
              <circle fill="#2E3EB3" cx={189.75} cy={72.96} r={3.96} />
              <circle fill="#2E3EB3" cx={271.17} cy={224.98} r={14.2} />
              <circle fill="#0086D4" cx={271.17} cy={224.98} r={5.8} />
              <circle fill="#0086D4" cx={362.328} cy={-3.61} r={6.28} />
              <circle fill="#0086D4" cx={359.99} cy={99.18} r={3.55} />
              <circle fill="#2E3EB3" cx={362.328} cy={149.14} r={2.33} />
              <g>
                <circle fill="#2E3EB3" cx={163.34} cy={119.22} r={14.2} />
                <circle fill="#0086D4" cx={163.34} cy={119.22} r={7.93} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={594.58} cy={417.79} r={5.92} />
              <circle fill="#2E3EB3" cx={662.08} cy={517.04} r={3.96} />
              <circle fill="#2E3EB3" cx={553.959} cy={455.63} r={3.96} />
              <circle fill="#2E3EB3" cx={773.58} cy={366.04} r={10.01} />
              <circle fill="#2E3EB3" cx={773.58} cy={366.04} r={6.55} />
              <circle fill="#2E3EB3" cx={697.408} cy={544.02} r={14.2} />
              <circle fill="#0086D4" cx={697.408} cy={544.02} r={5.8} />
              <circle fill="#0086D4" cx={673.368} cy={471.85} r={6.28} />
              <circle fill="#0086D4" cx={733.77} cy={380.42} r={3.55} />
              <circle fill="#2E3EB3" cx={758.17} cy={486.56} r={2.33} />
              <g>
                <circle fill="#2E3EB3" cx={583.84} cy={521.48} r={6.939} />
                <circle fill="#0086D4" cx={583.84} cy={521.48} r={3.87} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={199.68} cy={665.631} r={5.92} />
              <circle fill="#2E3EB3" cx={262.28} cy={760.89} r={4.81} />
              <circle fill="#2E3EB3" cx={159.06} cy={703.48} r={3.96} />
              <circle fill="#2E3EB3" cx={378.67} cy={613.89} r={3.96} />
              <circle fill="#2E3EB3" cx={305.198} cy={793.67} r={17.25} />
              <circle fill="#0086D4" cx={305.198} cy={793.67} r={7.05} />
              <circle fill="#0086D4" cx={278.46} cy={719.7} r={6.28} />
              <circle fill="#0086D4" cx={338.868} cy={628.27} r={3.55} />
              <circle fill="#2E3EB3" cx={363.27} cy={734.41} r={4.66} />
              <g>
                <circle fill="#2E3EB3" cx={188.93} cy={769.322} r={6.94} />
                <circle fill="#0086D4" cx={188.93} cy={769.32} r={3.87} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={1788.49} cy={776.11} r={5.92} />
              <circle fill="#2E3EB3" cx={1833.65} cy={887.32} r={3.96} />
              <circle fill="#2E3EB3" cx={1740.83} cy={804.592} r={3.96} />
              <circle fill="#2E3EB3" cx={1974.36} cy={763.092} r={3.96} />
              <circle fill="#2E3EB3" cx={1898.18} cy={819.25} r={14.2} />
              <circle fill="#0086D4" cx={1898.18} cy={819.25} r={5.8} />
              <circle fill="#0086D4" cx={1851.07} cy={749.42} r={6.28} />
              <circle fill="#0086D4" cx={1932.42} cy={768.79} r={3.55} />
              <circle fill="#2E3EB3" cx={1934} cy={877.69} r={2.33} />
              <g>
                <circle fill="#2E3EB3" cx={1756.22} cy={875.24} r={14.2} />
                <circle fill="#0086D4" cx={1756.22} cy={875.24} r={7.93} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={2041.75} cy={364.49} r={5.92} />
              <circle fill="#2E3EB3" cx={1931.18} cy={317.78} r={3.96} />
              <circle fill="#2E3EB3" cx={2055.31} cy={310.65} r={3.96} />
              <circle fill="#2E3EB3" cx={1947.52} cy={555.2} r={3.96} />
              <circle fill="#2E3EB3" cx={2010.1} cy={519.33} r={14.2} />
              <circle fill="#0086D4" cx={2010.1} cy={519.33} r={5.8} />
              <circle fill="#0086D4" cx={1970.4} cy={406.51} r={6.28} />
              <circle fill="#0086D4" cx={2068.64} cy={433.85} r={3.55} />
              <circle fill="#2E3EB3" cx={1867.03} cy={395.56} r={2.33} />
              <g>
                <circle fill="#2E3EB3" cx={1994.47} cy={271.58} r={14.2} />
                <circle fill="#0086D4" cx={1994.47} cy={271.58} r={7.93} />
              </g>
            </g>
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1304.97} cy={826.68} r={1.94} />
            <circle fill="#0086D4" cx={1345.51} cy={805.1} r={2.84} />
            <circle fill="#2E3EB3" cx={1353.06} cy={837.79} r={1.14} />
            <circle fill="#2E3EB3" cx={1270.9} cy={809.04} r={6.95} />
            <circle fill="#0086D4" cx={1270.9} cy={809.04} r={3.88} />
            <g>
              <circle fill="#2E3EB3" cx={1080.24} cy={813.901} r={2.36} />
              <circle fill="#2E3EB3" cx={1104.58} cy={824.22} r={8.45} />
              <circle fill="#0086D4" cx={1104.58} cy={824.22} r={3.45} />
              <g>
                <circle fill="#2E3EB3" cx={1046.48} cy={826.82} r={3.4} />
                <circle fill="#0086D4" cx={1046.48} cy={826.82} r={1.9} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={1805.89} cy={635.45} r={2.9} />
              <circle fill="#2E3EB3" cx={1840.84} cy={682.7} r={1.94} />
              <circle fill="#2E3EB3" cx={1786.76} cy={654.76} r={1.94} />
              <circle fill="#2E3EB3" cx={1892.459} cy={606.67} r={1.94} />
              <circle fill="#2E3EB3" cx={1863.16} cy={642.57} r={6.95} />
              <circle fill="#0086D4" cx={1863.16} cy={642.57} r={2.84} />
              <circle fill="#0086D4" cx={1832.33} cy={615.18} r={3.07} />
              <circle fill="#0086D4" cx={1873.26} cy={614.47} r={1.74} />
              <circle fill="#2E3EB3" cx={1887.26} cy={665.93} r={1.14} />
              <g>
                <circle fill="#2E3EB3" cx={1802.65} cy={686.39} r={6.95} />
                <circle fill="#0086D4" cx={1802.65} cy={686.39} r={3.88} />
              </g>
            </g>
            <g>
              <circle fill="#2E3EB3" cx={1875.93} cy={409.43} r={2.9} />
              <circle fill="#2E3EB3" cx={1817.81} cy={400.73} r={1.94} />
              <circle fill="#2E3EB3" cx={1875.81} cy={382.25} r={1.94} />
              <circle fill="#2E3EB3" cx={1854.44} cy={511.34} r={1.94} />
              <circle fill="#2E3EB3" cx={1920.539} cy={482.53} r={6.95} />
              <circle fill="#0086D4" cx={1920.539} cy={482.531} r={2.84} />
              <circle fill="#0086D4" cx={1847.198} cy={438.04} r={3.07} />
              <circle fill="#0086D4" cx={1861.41} cy={461.631} r={1.74} />
              <circle fill="#2E3EB3" cx={1796.84} cy={445.42} r={1.14} />
              <g>
                <circle fill="#2E3EB3" cx={1842.198} cy={371.12} r={6.95} />
                <circle fill="#0086D4" cx={1842.198} cy={371.12} r={3.88} />
              </g>
            </g>
          </g>
        </g>
        <g>
          <circle fill="#0086D4" cx={2105.81} cy={760.801} r={2.59} />
          <g fill="#0086D4">
            <circle cx={1971.76} cy={793.63} r={3.87} />
            <circle cx={1986.448} cy={716.52} r={2.59} />
            <circle cx={2008.05} cy={794.91} r={2.59} />
            <circle cx={1865.07} cy={734.79} r={2.59} />
            <circle cx={1926.84} cy={730.99} r={9.29} />
            <circle cx={1926.84} cy={730.99} r={3.79} />
            <circle cx={1927.92} cy={786.07} r={4.1} />
            <circle cx={1890.119} cy={746.54} r={2.32} />
            <circle cx={1927.92} cy={686.18} r={1.53} />
            <g>
              <circle cx={2024.68} cy={750.65} r={9.29} />
              <circle cx={2024.68} cy={750.65} r={5.18} />
            </g>
            <g>
              <circle cx={1731.4} cy={838.35} r={3.87} />
              <circle cx={1716.709} cy={915.45} r={2.59} />
              <circle cx={1695.11} cy={837.07} r={2.59} />
              <circle cx={1838.09} cy={897.19} r={2.59} />
              <circle cx={1720.57} cy={944.27} r={9.29} />
              <circle cx={1720.57} cy={944.27} r={3.79} />
              <circle cx={1742.83} cy={899.781} r={4.1} />
              <circle cx={1813.039} cy={885.43} r={2.32} />
              <circle cx={1775.24} cy={945.8} r={1.53} />
              <g>
                <circle cx={1678.48} cy={881.33} r={4.54} />
                <circle cx={1678.48} cy={881.33} r={2.53} />
              </g>
            </g>
            <g>
              <circle cx={2011.52} cy={967.1} r={2.59} />
              <circle cx={2082.22} cy={1007.25} r={2.59} />
              <circle cx={1988.41} cy={949.45} r={9.29} />
              <circle cx={1988.41} cy={949.45} r={3.79} />
              <circle cx={2004.129} cy={996.65} r={4.1} />
              <circle cx={1948.67} cy={987.03} r={1.53} />
              <g>
                <circle cx={2062.68} cy={964.19} r={4.54} />
                <circle cx={2062.68} cy={964.19} r={2.53} />
              </g>
            </g>
            <g>
              <circle cx={1153.369} cy={806.2} r={2.59} />
              <circle cx={1233.99} cy={815.14} r={4.1} />
              <circle cx={1180.789} cy={802.47} r={2.32} />
            </g>
            <g>
              <circle cx={1170.92} cy={942.14} r={2.59} />
              <circle cx={1130} cy={965.6} r={9.29} />
              <circle cx={1130} cy={965.6} r={3.79} />
              <circle cx={1145.4} cy={1004.14} r={2.32} />
            </g>
          </g>
          <g fill="#0086D4">
            <circle cx={1789.57} cy={1016.27} r={1.9} />
            <circle cx={1787.15} cy={977.92} r={1.27} />
            <circle cx={1806.93} cy={1012.47} r={1.27} />
            <circle cx={1731.81} cy={1001.35} r={1.27} />
            <circle cx={1760.64} cy={992.03} r={4.55} />
            <circle cx={1760.64} cy={992.031} r={1.86} />
            <circle cx={1767.86} cy={1018.02} r={2.01} />
            <circle cx={1745.119} cy={1003.87} r={1.14} />
            <circle cx={1755.698} cy={970.65} r={0.75} />
            <g>
              <circle cx={1809.43} cy={989.45} r={4.55} />
              <circle cx={1809.43} cy={989.45} r={2.54} />
            </g>
            <g>
              <circle cx={1484.97} cy={853.42} r={1.9} />
              <circle cx={1487.379} cy={891.77} r={1.271} />
              <circle cx={1467.6} cy={857.22} r={1.27} />
              <circle cx={1646.209} cy={996.58} r={1.27} />
              <circle cx={1542.72} cy={868.34} r={1.27} />
              <circle cx={1583.94} cy={992.42} r={2.51} />
              <circle cx={1492.72} cy={904.97} r={4.55} />
              <circle cx={1492.72} cy={904.97} r={1.86} />
              <circle cx={1448.25} cy={899.04} r={3.76} />
              <circle cx={1448.25} cy={899.04} r={1.54} />
              <circle cx={1514.58} cy={1018.02} r={1.86} />
              <circle cx={1420.06} cy={974.79} r={1.86} />
              <circle cx={1497.86} cy={881.16} r={2.01} />
              <circle cx={1529.41} cy={865.81} r={1.14} />
              <circle cx={1518.83} cy={899.041} r={0.75} />
              <g>
                <circle cx={1465.1} cy={880.24} r={2.22} />
                <circle cx={1465.1} cy={880.24} r={1.24} />
              </g>
            </g>
            <g>
              <circle cx={1404.448} cy={995.58} r={1.9} />
              <circle cx={1433.28} cy={1020.99} r={1.27} />
              <circle cx={1394.869} cy={1010.55} r={1.27} />
              <circle cx={1619.698} cy={982.8} r={1.27} />
              <circle cx={1455.85} cy={965.29} r={1.27} />
              <circle cx={1433.19} cy={1006.08} r={2.01} />
              <circle cx={1444.65} cy={972.92} r={1.14} />
              <circle cx={1460.66} cy={1003.9} r={0.75} />
            </g>
            <g>
              <circle cx={1662.3} cy={905.81} r={1.9} />
              <circle cx={1633.48} cy={880.402} r={1.27} />
              <circle cx={1671.89} cy={890.84} r={1.27} />
              <circle cx={1610.91} cy={936.1} r={1.27} />
              <circle cx={1620.369} cy={874.841} r={4.55} />
              <circle cx={1620.369} cy={874.84} r={1.86} />
              <circle cx={1633.57} cy={895.31} r={2.01} />
              <circle cx={1634.74} cy={945.801} r={2.01} />
              <circle cx={1472.039} cy={1001.35} r={2.01} />
              <circle cx={1622.11} cy={928.47} r={1.14} />
              <circle cx={1606.1} cy={897.5} r={0.75} />
              <circle cx={1657.39} cy={872.8} r={2.22} />
              <circle cx={1657.39} cy={872.801} r={1.24} />
              <circle cx={1426.198} cy={900.16} r={1.9} />
              <circle cx={1415.68} cy={937.12} r={1.27} />
              <circle cx={1408.56} cy={897.96} r={1.27} />
              <circle cx={1416.31} cy={951.35} r={4.55} />
              <circle cx={1416.31} cy={951.35} r={1.86} />
              <circle cx={1429.1} cy={930.62} r={2.01} />
              <circle cx={1463.959} cy={926.67} r={1.14} />
              <g>
                <circle cx={1398.53} cy={918.821} r={2.22} />
                <circle cx={1398.53} cy={918.82} r={1.24} />
              </g>
            </g>
            <g>
              <circle cx={1765.06} cy={797.531} r={1.9} />
              <circle cx={1738.07} cy={772.97} r={1.54} />
              <circle cx={1774.65} cy={782.56} r={1.27} />
              <circle cx={1713.66} cy={827.822} r={1.27} />
              <circle cx={1722.15} cy={766.22} r={5.52} />
              <circle cx={1722.15} cy={766.22} r={2.26} />
              <circle cx={1736.32} cy={787.031} r={2.01} />
              <circle cx={1724.86} cy={820.19} r={1.14} />
              <circle cx={1708.85} cy={789.22} r={1.49} />
              <g>
                <circle cx={1760.14} cy={764.52} r={2.22} />
                <circle cx={1760.14} cy={764.52} r={1.24} />
              </g>
            </g>
            <g>
              <circle cx={1263.53} cy={889.66} r={1.9} />
              <circle cx={1240.68} cy={858.77} r={1.271} />
              <circle cx={1276.05} cy={877.04} r={1.27} />
              <circle cx={1206.93} cy={908.49} r={1.27} />
              <circle cx={1226.08} cy={885.01} r={4.55} />
              <circle cx={1226.08} cy={885.01} r={1.86} />
              <circle cx={1246.25} cy={902.92} r={2.01} />
              <circle cx={1219.48} cy={903.38} r={1.14} />
              <circle cx={1210.33} cy={869.74} r={0.75} />
            </g>
            <g>
              <circle cx={1231.789} cy={970.822} r={1.27} />
              <circle cx={1215.24} cy={986.93} r={4.55} />
              <circle cx={1215.24} cy={986.93} r={1.86} />
              <circle cx={1236.52} cy={1018.76} r={2.01} />
              <circle cx={1227.23} cy={1003.33} r={1.14} />
              <circle cx={1269.448} cy={1013.93} r={0.75} />
            </g>
          </g>
        </g>
        <g>
          <circle fill="#0086D4" cx={1740.35} cy={30.4} r={2.69} />
          <g fill="#0086D4">
            <circle cx={2028.06} cy={241.5} r={4.56} />
            <circle cx={2073.868} cy={224.91} r={4.09} />
            <circle cx={2045.69} cy={102.76} r={2.69} />
          </g>
          <circle fill="#0086D4" cx={568.289} cy={244.36} r={6.82} />
          <circle fill="#0086D4" cx={695.6} cy={298.031} r={4.56} />
          <circle fill="#0086D4" cx={552.72} cy={306.35} r={4.56} />
          <circle fill="#0086D4" cx={676.58} cy={24.76} r={4.56} />
          <circle fill="#2E3EB3" cx={604.59} cy={58.46} r={16.35} />
          <circle fill="#0086D4" cx={604.59} cy={58.46} r={6.68} />
          <circle fill="#0086D4" cx={650.38} cy={195.94} r={7.22} />
          <circle fill="#0086D4" cx={631.75} cy={133.93} r={4.09} />
          <circle fill="#0086D4" cx={769.38} cy={208.46} r={2.69} />
          <g>
            <circle fill="#2E3EB3" cx={481.65} cy={283.68} r={16.35} />
            <circle fill="#0086D4" cx={481.65} cy={283.68} r={9.12} />
          </g>
          <g fill="#0086D4">
            <circle cx={1765.66} cy={154.44} r={3.34} />
            <circle cx={1761.36} cy={86.93} r={2.23} />
            <circle cx={1796.22} cy={147.72} r={2.23} />
            <circle cx={1663.97} cy={128.24} r={2.23} />
            <circle cx={1714.709} cy={111.81} r={8} />
            <circle cx={1714.709} cy={111.81} r={3.27} />
            <circle cx={1727.44} cy={157.552} r={3.54} />
            <circle cx={1687.41} cy={132.68} r={2} />
            <circle cx={1705.99} cy={74.18} r={1.31} />
            <g>
              <circle cx={1800.6} cy={107.2} r={8} />
              <circle cx={1800.6} cy={107.2} r={4.47} />
            </g>
            <g>
              <circle cx={1513.289} cy={119.97} r={2.23} />
              <circle cx={1403.67} cy={112.73} r={4.41} />
              <circle cx={1281.619} cy={157.88} r={3.27} />
              <circle cx={1115.19} cy={81.91} r={3.27} />
            </g>
            <g>
              <circle cx={1138.52} cy={163.21} r={2.23} />
              <circle cx={1466.61} cy={95.74} r={2.23} />
              <circle cx={1178.17} cy={65.14} r={2.23} />
              <circle cx={1429.05} cy={213.29} r={4.41} />
              <circle cx={1161.59} cy={172.98} r={8} />
              <circle cx={1161.59} cy={172.98} r={3.27} />
              <circle cx={1098.9} cy={221} r={6.62} />
              <circle cx={1098.9} cy={221} r={2.71} />
              <circle cx={1355.198} cy={175.47} r={3.27} />
              <circle cx={1183.8} cy={239.44} r={3.27} />
              <circle cx={1158.459} cy={78.58} r={2} />
              <g>
                <circle cx={1096.44} cy={176.62} r={3.91} />
                <circle cx={1096.44} cy={176.62} r={2.18} />
              </g>
            </g>
            <g>
              <circle cx={1451.07} cy={13.54} r={2.23} />
              <circle cx={1493.039} cy={30.59} r={3.54} />
              <circle cx={1470.78} cy={0.11} r={2} />
              <circle cx={1107.43} cy={15.61} r={2.23} />
              <circle cx={1108.56} cy={40.64} r={8} />
              <circle cx={1108.56} cy={40.64} r={3.27} />
              <circle cx={1131.039} cy={4.14} r={3.54} />
            </g>
            <g>
              <circle cx={759.13} cy={192.49} r={3.34} />
              <circle cx={826.03} cy={202.45} r={2.23} />
              <circle cx={759.28} cy={223.781} r={2.23} />
              <circle cx={783.77} cy={75.17} r={2.23} />
              <circle cx={754.658} cy={103.53} r={8} />
              <circle cx={754.658} cy={103.531} r={3.27} />
              <circle cx={792.17} cy={159.54} r={3.54} />
              <circle cx={775.789} cy={132.39} r={2} />
              <circle cx={850.118} cy={151} r={1.31} />
              <g>
                <circle cx={797.98} cy={236.56} r={8} />
                <circle cx={797.98} cy={236.56} r={4.47} />
              </g>
            </g>
          </g>
        </g>
        <g>
          <circle fill="#2E3EB3" cx={897.86} cy={647.73} r={6.82} />
          <circle fill="#2E3EB3" cx={872.11} cy={783.47} r={4.56} />
          <circle fill="#2E3EB3" cx={1085.75} cy={751.16} r={4.56} />
          <circle fill="#2E3EB3" cx={918.06} cy={755.85} r={7.22} />
          <circle fill="#2E3EB3" cx={1041.629} cy={730.5} r={4.09} />
          <circle fill="#2E3EB3" cx={957.06} cy={575.83} r={3.34} />
          <circle fill="#2E3EB3" cx={909.52} cy={532.63} r={2.71} />
          <circle fill="#2E3EB3" cx={973.92} cy={549.47} r={2.23} />
          <circle fill="#2E3EB3" cx={866.64} cy={629.21} r={2.23} />
          <g fill="#2E3EB3">
            <circle cx={881.49} cy={520.77} r={9.72} />
            <circle cx={881.49} cy={520.77} r={3.97} />
          </g>
          <circle fill="#2E3EB3" cx={906.47} cy={557.39} r={3.54} />
          <circle fill="#2E3EB3" cx={886.34} cy={615.77} r={2} />
          <path
            fill="#2E3EB3"
            d="M855.86 559.93a2.638 2.638 0 013.6-.931 2.64 2.64 0 01.932 3.601 2.64 2.64 0 01-3.602.93 2.615 2.615 0 01-.93-3.6"
          />
          <g fill="#2E3EB3">
            <circle cx={948.369} cy={517.73} r={3.91} />
            <circle cx={948.369} cy={517.73} r={2.18} />
          </g>
          <circle fill="#2E3EB3" cx={1090.44} cy={713.92} r={5.92} />
          <circle fill="#2E3EB3" cx={1112.9} cy={596.01} r={3.96} />
          <circle fill="#2E3EB3" cx={1145.92} cy={715.88} r={3.96} />
          <circle fill="#2E3EB3" cx={927.28} cy={623.94} r={3.96} />
          <circle fill="#2E3EB3" cx={1106.99} cy={551.95} r={14.2} />
          <circle fill="#0086D4" cx={1106.99} cy={551.95} r={5.8} />
          <circle fill="#0086D4" cx={1072.959} cy={619.98} r={6.28} />
          <circle fill="#0086D4" cx={965.59} cy={641.92} r={3.55} />
          <circle fill="#2E3EB3" cx={1023.39} cy={549.62} r={2.33} />
          <circle fill="#2E3EB3" cx={1171.35} cy={648.2} r={6.94} />
          <circle fill="#0086D4" cx={1171.35} cy={648.2} r={3.87} />
          <circle fill="#2E3EB3" cx={1301.28} cy={768.031} r={2.9} />
          <circle fill="#2E3EB3" cx={1274.72} cy={773.85} r={1.94} />
          <circle fill="#2E3EB3" cx={1389.6} cy={790.85} r={1.94} />
          <circle fill="#0086D4" cx={1334.48} cy={765.361} r={3.07} />
          <circle fill="#0086D4" cx={1369.24} cy={786.99} r={1.74} />
          <circle fill="#2E3EB3" cx={1001.48} cy={441.84} r={2.9} />
          <circle fill="#2E3EB3" cx={1005.17} cy={500.49} r={1.94} />
          <circle fill="#2E3EB3" cx={974.93} cy={447.66} r={1.94} />
          <circle fill="#2E3EB3" cx={1089.8} cy={464.67} r={1.94} />
          <circle fill="#2E3EB3" cx={1045.709} cy={478.91} r={6.95} />
          <circle fill="#0086D4" cx={1045.709} cy={478.91} r={2.84} />
          <circle fill="#0086D4" cx={1034.68} cy={439.17} r={3.07} />
          <circle fill="#0086D4" cx={1069.448} cy={460.801} r={1.74} />
          <circle fill="#2E3EB3" cx={1053.26} cy={511.61} r={1.14} />
          <g>
            <circle fill="#2E3EB3" cx={971.1} cy={482.85} r={6.95} />
            <circle fill="#0086D4" cx={971.1} cy={482.85} r={3.88} />
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1467.28} cy={690.88} r={2.9} />
            <circle fill="#2E3EB3" cx={1463.58} cy={632.23} r={1.939} />
            <circle fill="#2E3EB3" cx={1493.83} cy={685.06} r={1.94} />
            <circle fill="#2E3EB3" cx={1220.698} cy={471.95} r={1.94} />
            <circle fill="#2E3EB3" cx={1378.948} cy={668.06} r={1.94} />
            <circle fill="#2E3EB3" cx={1315.93} cy={478.31} r={3.83} />
            <circle fill="#2E3EB3" cx={1455.42} cy={612.05} r={6.95} />
            <circle fill="#0086D4" cx={1455.42} cy={612.051} r={2.84} />
            <circle fill="#2E3EB3" cx={1523.43} cy={621.11} r={5.75} />
            <circle fill="#0086D4" cx={1523.43} cy={621.111} r={2.35} />
            <circle fill="#0086D4" cx={1421.99} cy={439.17} r={2.84} />
            <circle fill="#0086D4" cx={1566.53} cy={505.27} r={2.84} />
            <circle fill="#0086D4" cx={1447.56} cy={648.46} r={3.07} />
            <circle fill="#0086D4" cx={1399.31} cy={671.92} r={1.74} />
            <circle fill="#2E3EB3" cx={1415.49} cy={621.11} r={1.14} />
            <g>
              <circle fill="#2E3EB3" cx={1497.66} cy={649.87} r={3.4} />
              <circle fill="#0086D4" cx={1497.66} cy={649.87} r={1.9} />
            </g>
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1590.4} cy={473.48} r={2.9} />
            <circle fill="#2E3EB3" cx={1546.31} cy={434.62} r={1.94} />
            <circle fill="#2E3EB3" cx={1605.06} cy={450.59} r={1.94} />
            <circle fill="#2E3EB3" cx={1261.24} cy={493.031} r={1.94} />
            <circle fill="#2E3EB3" cx={1511.8} cy={519.8} r={1.94} />
            <circle fill="#2E3EB3" cx={1293.948} cy={390.93} r={3.83} />
            <circle fill="#2E3EB3" cx={1580.77} cy={384.45} r={5.75} />
            <circle fill="#0086D4" cx={1580.77} cy={384.45} r={2.35} />
            <circle fill="#0086D4" cx={1358.08} cy={423.83} r={2.84} />
            <circle fill="#0086D4" cx={1507.02} cy={368.38} r={2.84} />
            <circle fill="#0086D4" cx={1546.459} cy={457.43} r={3.07} />
            <circle fill="#0086D4" cx={1528.93} cy={508.14} r={1.74} />
            <circle fill="#2E3EB3" cx={1504.448} cy={460.77} r={1.14} />
            <g>
              <circle fill="#2E3EB3" cx={1582.879} cy={423} r={3.4} />
              <circle fill="#0086D4" cx={1582.879} cy={423} r={1.9} />
            </g>
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1196.09} cy={610.76} r={2.9} />
            <circle fill="#2E3EB3" cx={1240.18} cy={649.62} r={1.94} />
            <circle fill="#2E3EB3" cx={1181.43} cy={633.651} r={1.94} />
            <circle fill="#2E3EB3" cx={1274.69} cy={564.45} r={1.94} />
            <circle fill="#0086D4" cx={1240.03} cy={626.82} r={3.07} />
            <circle fill="#0086D4" cx={1238.24} cy={549.62} r={3.07} />
            <circle fill="#0086D4" cx={1487.039} cy={464.67} r={3.07} />
            <circle fill="#0086D4" cx={1257.56} cy={576.11} r={1.74} />
            <circle fill="#2E3EB3" cx={1282.039} cy={623.47} r={1.14} />
            <circle fill="#2E3EB3" cx={1203.61} cy={661.24} r={3.4} />
            <circle fill="#0086D4" cx={1203.61} cy={661.24} r={1.9} />
            <circle fill="#2E3EB3" cx={1557.14} cy={619.4} r={2.9} />
            <circle fill="#2E3EB3" cx={1573.23} cy={562.87} r={1.94} />
            <circle fill="#2E3EB3" cx={1584.119} cy={622.77} r={1.94} />
            <circle fill="#2E3EB3" cx={1572.26} cy={541.13} r={6.95} />
            <circle fill="#0086D4" cx={1572.26} cy={541.131} r={2.84} />
            <circle fill="#0086D4" cx={1552.709} cy={572.83} r={3.07} />
            <circle fill="#0086D4" cx={1499.39} cy={578.85} r={1.74} />
            <g>
              <circle fill="#2E3EB3" cx={1599.459} cy={590.861} r={3.4} />
              <circle fill="#0086D4" cx={1599.459} cy={590.861} r={1.9} />
            </g>
          </g>
          <circle fill="#2E3EB3" cx={1038.959} cy={776.34} r={2.9} />
          <path fill="#2E3EB3" d="M1023.31 800.9a1.941 1.941 0 112.66-.68" />
          <circle fill="#2E3EB3" cx={1117.55} cy={730.031} r={1.94} />
          <circle fill="#0086D4" cx={1082.9} cy={792.4} r={3.07} />
          <circle fill="#0086D4" cx={1100.43} cy={741.69} r={1.74} />
          <circle fill="#2E3EB3" cx={1124.91} cy={789.06} r={2.28} />
          <circle fill="#0086D4" cx={1306.08} cy={779.06} r={2.59} />
          <circle fill="#0086D4" cx={1179.76} cy={731.26} r={1.53} />
          <path
            fill="#0086D4"
            d="M1609.66 799.48a1.26 1.26 0 01.908-1.54 1.26 1.26 0 011.54.91 1.26 1.26 0 01-.909 1.54"
          />
          <circle fill="#0086D4" cx={1535.76} cy={788.04} r={1.27} />
          <circle fill="#0086D4" cx={1549.08} cy={790.571} r={1.14} />
          <circle fill="#0086D4" cx={1559.66} cy={757.342} r={0.75} />
        </g>
        <g>
          <circle fill="#2E3EB3" cx={1700.25} cy={598.48} r={6.82} />
          <circle fill="#2E3EB3" cx={1899.09} cy={296.19} r={4.56} />
          <circle fill="#2E3EB3" cx={1685.459} cy={328.5} r={4.56} />
          <circle fill="#2E3EB3" cx={1853.14} cy={323.82} r={7.22} />
          <circle fill="#2E3EB3" cx={1729.58} cy={349.16} r={4.09} />
          <circle fill="#2E3EB3" cx={1633.74} cy={663.67} r={3.34} />
          <circle fill="#2E3EB3" cx={1676.42} cy={711.68} r={2.71} />
          <circle fill="#2E3EB3" cx={1614.18} cy={688.09} r={2.23} />
          <circle fill="#2E3EB3" cx={1729.33} cy={620.21} r={2.23} />
          <g fill="#2E3EB3">
            <circle cx={1703.03} cy={726.45} r={9.72} />
            <circle cx={1703.03} cy={726.45} r={3.97} />
          </g>
          <circle fill="#2E3EB3" cx={1682.09} cy={687.39} r={3.54} />
          <circle fill="#2E3EB3" cx={1708.31} cy={631.47} r={2} />
          <path
            fill="#2E3EB3"
            d="M1732.69 690.24c-.87 1.16-2.51 1.41-3.68.54s-1.41-2.51-.541-3.68a2.633 2.633 0 013.681-.54c1.16.87 1.4 2.52.54 3.68"
          />
          <g fill="#2E3EB3">
            <circle cx={1636.209} cy={722.37} r={3.91} />
            <circle cx={1636.209} cy={722.37} r={2.18} />
          </g>
          <circle fill="#2E3EB3" cx={1761.03} cy={418.69} r={5.92} />
          <circle fill="#2E3EB3" cx={1761.84} cy={538.72} r={3.96} />
          <circle fill="#2E3EB3" cx={1706.209} cy={427.52} r={3.96} />
          <circle fill="#2E3EB3" cx={1668.47} cy={619.001} r={3.96} />
          <circle fill="#2E3EB3" cx={1776.17} cy={580.8} r={14.2} />
          <circle fill="#0086D4" cx={1776.17} cy={580.8} r={5.8} />
          <circle fill="#0086D4" cx={1796.379} cy={507.47} r={6.28} />
          <circle fill="#0086D4" cx={1632.289} cy={597.05} r={3.55} />
          <circle fill="#2E3EB3" cx={1565} cy={682.69} r={2.33} />
          <circle fill="#2E3EB3" cx={1694.379} cy={498.85} r={6.94} />
          <circle fill="#0086D4" cx={1694.379} cy={498.85} r={3.87} />
          <circle fill="#2E3EB3" cx={1469.93} cy={311.63} r={2.9} />
          <circle fill="#2E3EB3" cx={1496.48} cy={305.81} r={1.94} />
          <circle fill="#2E3EB3" cx={1381.61} cy={288.81} r={1.94} />
          <circle fill="#0086D4" cx={1436.73} cy={314.31} r={3.07} />
          <circle fill="#0086D4" cx={1401.97} cy={292.67} r={1.74} />
          <circle fill="#2E3EB3" cx={1575.33} cy={792.18} r={2.9} />
          <circle fill="#2E3EB3" cx={1577.9} cy={733.47} r={1.94} />
          <circle fill="#2E3EB3" cx={1602.35} cy={789.22} r={1.94} />
          <circle fill="#2E3EB3" cx={1489.94} cy={760.1} r={1.94} />
          <circle fill="#2E3EB3" cx={1535.289} cy={750.62} r={6.95} />
          <circle fill="#0086D4" cx={1535.289} cy={750.62} r={2.84} />
          <circle fill="#0086D4" cx={1542.03} cy={791.31} r={3.07} />
          <circle fill="#0086D4" cx={1509.77} cy={766.1} r={1.74} />
          <circle fill="#2E3EB3" cx={1531.26} cy={717.31} r={1.14} />
          <circle fill="#2E3EB3" cx={1609.9} cy={754.63} r={6.95} />
          <circle fill="#0086D4" cx={1609.9} cy={754.63} r={3.88} />
          <circle fill="#2E3EB3" cx={1303.93} cy={388.78} r={2.9} />
          <circle fill="#2E3EB3" cx={1307.619} cy={447.43} r={1.94} />
          <circle fill="#2E3EB3" cx={1277.379} cy={394.6} r={1.94} />
          <circle fill="#2E3EB3" cx={1822.53} cy={499.6} r={1.94} />
          <circle fill="#2E3EB3" cx={1392.25} cy={411.61} r={1.94} />
          <circle fill="#2E3EB3" cx={1729.709} cy={521.81} r={3.83} />
          <circle fill="#2E3EB3" cx={1315.789} cy={467.61} r={6.95} />
          <circle fill="#0086D4" cx={1315.789} cy={467.61} r={2.84} />
          <circle fill="#2E3EB3" cx={1247.78} cy={458.552} r={5.75} />
          <circle fill="#0086D4" cx={1247.78} cy={458.55} r={2.35} />
          <circle fill="#0086D4" cx={1349.22} cy={640.5} r={2.84} />
          <circle fill="#0086D4" cx={1204.68} cy={574.39} r={2.84} />
          <circle fill="#0086D4" cx={1323.65} cy={431.21} r={3.07} />
          <circle fill="#0086D4" cx={1371.9} cy={407.74} r={1.74} />
          <circle fill="#2E3EB3" cx={1355.72} cy={458.55} r={1.14} />
          <g>
            <circle fill="#2E3EB3" cx={1273.55} cy={429.8} r={3.4} />
            <circle fill="#0086D4" cx={1273.55} cy={429.8} r={1.9} />
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1180.81} cy={606.18} r={2.9} />
            <circle fill="#2E3EB3" cx={1224.89} cy={645.04} r={1.94} />
            <circle fill="#2E3EB3" cx={1166.15} cy={629.072} r={1.94} />
            <circle fill="#2E3EB3" cx={1777.56} cy={491.51} r={1.94} />
            <circle fill="#2E3EB3" cx={1259.4} cy={559.87} r={1.94} />
            <circle fill="#2E3EB3" cx={1777.56} cy={610.76} r={3.83} />
            <circle fill="#2E3EB3" cx={1244.93} cy={653.54} r={6.95} />
            <circle fill="#0086D4" cx={1244.93} cy={653.54} r={2.84} />
            <circle fill="#2E3EB3" cx={1190.44} cy={695.22} r={5.75} />
            <circle fill="#0086D4" cx={1190.44} cy={695.22} r={2.35} />
            <circle fill="#0086D4" cx={1705.64} cy={586.35} r={2.84} />
            <circle fill="#0086D4" cx={1264.18} cy={711.29} r={2.84} />
            <circle fill="#0086D4" cx={1224.75} cy={622.24} r={3.07} />
            <circle fill="#0086D4" cx={1242.28} cy={571.531} r={1.74} />
            <circle fill="#2E3EB3" cx={1266.76} cy={618.89} r={1.14} />
            <g>
              <circle fill="#2E3EB3" cx={1188.33} cy={656.66} r={3.4} />
              <circle fill="#0086D4" cx={1188.33} cy={656.66} r={1.9} />
            </g>
          </g>
          <g>
            <circle fill="#2E3EB3" cx={1575.11} cy={468.9} r={2.9} />
            <circle fill="#2E3EB3" cx={1531.03} cy={430.04} r={1.94} />
            <circle fill="#2E3EB3" cx={1589.77} cy={446.01} r={1.94} />
            <circle fill="#2E3EB3" cx={1743.51} cy={427.31} r={1.939} />
            <circle fill="#2E3EB3" cx={1522.14} cy={397.16} r={6.95} />
            <circle fill="#0086D4" cx={1522.14} cy={397.16} r={2.84} />
            <circle fill="#0086D4" cx={1531.17} cy={452.85} r={3.07} />
            <circle fill="#0086D4" cx={1782.72} cy={430.65} r={3.07} />
            <circle fill="#0086D4" cx={1284.17} cy={615} r={3.07} />
            <circle fill="#0086D4" cx={1756.4} cy={411.09} r={1.74} />
            <circle fill="#2E3EB3" cx={1489.17} cy={456.19} r={1.14} />
            <circle fill="#2E3EB3" cx={1567.59} cy={418.42} r={3.4} />
            <circle fill="#0086D4" cx={1567.59} cy={418.42} r={1.9} />
            <circle fill="#2E3EB3" cx={1214.06} cy={460.27} r={2.899} />
            <circle fill="#2E3EB3" cx={1197.98} cy={516.79} r={1.94} />
            <circle fill="#2E3EB3" cx={1187.09} cy={456.901} r={1.94} />
            <circle fill="#2E3EB3" cx={1198.948} cy={538.53} r={6.95} />
            <circle fill="#0086D4" cx={1198.948} cy={538.531} r={2.84} />
            <circle fill="#0086D4" cx={1218.5} cy={506.84} r={3.07} />
            <circle fill="#0086D4" cx={1271.81} cy={500.81} r={1.74} />
            <g>
              <circle fill="#2E3EB3" cx={1171.74} cy={488.8} r={3.4} />
              <circle fill="#0086D4" cx={1171.74} cy={488.8} r={1.9} />
            </g>
          </g>
          <circle fill="#2E3EB3" cx={1732.25} cy={303.32} r={2.9} />
          <path fill="#2E3EB3" d="M1747.9 278.76a1.94 1.94 0 11-2.66.68" />
          <circle fill="#2E3EB3" cx={1653.65} cy={349.64} r={1.94} />
          <circle fill="#0086D4" cx={1688.31} cy={287.27} r={3.07} />
          <circle fill="#0086D4" cx={1670.78} cy={337.97} r={1.74} />
          <circle fill="#2E3EB3" cx={1646.3} cy={290.61} r={2.28} />
          <path
            fill="#0086D4"
            d="M1499.55 279.88a3.868 3.868 0 01-1.15 5.352 3.879 3.879 0 01-5.36-1.15 3.866 3.866 0 011.15-5.35"
          />
          <circle fill="#0086D4" cx={1525.82} cy={354.7} r={2.59} />
          <circle fill="#0086D4" cx={1465.129} cy={300.6} r={2.59} />
          <g fill="#0086D4">
            <circle cx={1568.03} cy={310.19} r={9.29} />
            <circle cx={1568.03} cy={310.19} r={3.79} />
          </g>
          <circle fill="#0086D4" cx={1591.448} cy={348.41} r={1.53} />
          <g fill="#0086D4">
            <circle cx={1475.19} cy={346.802} r={9.29} />
            <circle cx={1475.19} cy={346.8} r={5.18} />
          </g>
          <circle fill="#0086D4" cx={1180.1} cy={315.05} r={1.27} />
          <path
            fill="#0086D4"
            d="M1161.55 280.19c.17.68-.23 1.37-.91 1.54-.681.17-1.37-.23-1.54-.91s.23-1.37.91-1.54"
          />
          <circle fill="#0086D4" cx={1235.44} cy={291.62} r={1.27} />
          <g fill="#0086D4">
            <circle cx={1206.61} cy={300.93} r={4.55} />
            <circle cx={1206.61} cy={300.93} r={1.86} />
          </g>
          <circle fill="#0086D4" cx={1222.129} cy={289.09} r={1.14} />
          <circle fill="#0086D4" cx={1211.55} cy={322.32} r={0.75} />
          <g fill="#0086D4">
            <circle cx={1157.82} cy={303.52} r={4.55} />
            <circle cx={1157.82} cy={303.52} r={2.54} />
          </g>
        </g>
      </g>
      <g id="ilu">
        <g fill="#2E3EB3">
          <ellipse
            opacity={0.25}
            cx={1116}
            cy={569.09}
            rx={755.97}
            ry={769.89}
          />
          <circle opacity={0.25} cx={1116} cy={569.09} r={507.34} />
          <circle opacity={0.25} cx={1116} cy={569.09} r={418.24} />
        </g>
        <g fill="none" stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <path d="M125.68 412.28c.13-.82.31-1.63.55-2.43" />
          <path
            strokeDasharray="5.0036,10.0072"
            d="M131.198 401.25c54.4-61.448 487.54-47.83 1004.962 34.132 528.43 83.71 951.229 208.039 967.879 282.868"
          />
          <path d="M2104.55 723.22c-.021.84-.102 1.66-.23 2.49" />
        </g>
        <path
          fill="none"
          stroke="#2E3EB3"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M174.16 912.7c-25.642-70.18 374.81-280.95 894.42-470.77 519.608-189.82 961.618-286.82 987.26-216.642"
        />
        <defs>
          <circle id="SVGID_1_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <circle
          clipPath="url(#SVGID_2_)"
          fill="#FF5640"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          cx={1116}
          cy={569.09}
          r={431.93}
        />
        <defs>
          <circle id="SVGID_3_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_4_">
          <use xlinkHref="#SVGID_3_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_4_)"
          fill="#FF5640"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M1122.85 382.18l-493.59.47c-21.08.02-38.19-17.05-38.21-38.13h0c-.021-21.08 17.05-38.19 38.13-38.21l493.59-.47c21.08-.021 38.189 17.05 38.21 38.13h0c.03 21.082-17.05 38.19-38.13 38.21z"
        />
        <defs>
          <circle id="SVGID_5_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlinkHref="#SVGID_5_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_6_)"
          fill="#FFAC59"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M1720.49 439.53l-785.8.75c-10.18.011-18.439-8.229-18.45-18.409h0c-.01-10.181 8.23-18.439 18.41-18.451l785.8-.75c10.18-.01 18.44 8.23 18.45 18.41h0c.01 10.18-8.24 18.44-18.41 18.45z"
        />
        <defs>
          <circle id="SVGID_7_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_8_">
          <use xlinkHref="#SVGID_7_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_8_)"
          fill="#FFAC59"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M901.58 587.73l-336.302.32c-10.18.01-18.438-8.23-18.448-18.41h0c-.012-10.18 8.229-18.44 18.408-18.45l336.301-.32c10.181-.01 18.439 8.23 18.449 18.41h0c.012 10.181-8.238 18.44-18.408 18.45z"
        />
        <defs>
          <circle id="SVGID_9_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_10_">
          <use xlinkHref="#SVGID_9_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_10_)"
          fill="#FFAC59"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M996.5 789.35l-315.19.3c-10.18.01-18.44-8.23-18.45-18.41h0c-.01-10.18 8.23-18.438 18.41-18.449l315.189-.301c10.181-.01 18.439 8.23 18.449 18.41h0c.012 10.18-8.228 18.442-18.408 18.45z"
        />
        <defs>
          <circle id="SVGID_11_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_12_">
          <use xlinkHref="#SVGID_11_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_12_)"
          fill="#FF5640"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M1496.47 599.19l-82.48.08c-13.329.01-24.149-10.78-24.159-24.11h0c-.011-13.33 10.779-24.148 24.109-24.16l82.48-.078c13.33-.012 24.148 10.778 24.16 24.108h0c.01 13.33-10.78 24.15-24.11 24.16z"
        />
        <defs>
          <circle id="SVGID_13_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_14_">
          <use xlinkHref="#SVGID_13_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_14_)"
          fill="#FF5640"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M1410.99 511.711l-42.438.039c-6.86.012-12.432-5.55-12.432-12.408h0c-.01-6.86 5.551-12.432 12.41-12.432l42.44-.039c6.86-.011 12.43 5.549 12.43 12.409h0c.01 6.86-5.55 12.431-12.41 12.431z"
        />
        <defs>
          <circle id="SVGID_15_" cx={1116} cy={569.09} r={375.56} />
        </defs>
        <clipPath id="SVGID_16_">
          <use xlinkHref="#SVGID_15_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_16_)"
          fill="#FFAC59"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M1179.09 214.342l-22.15.02c-2.07-18.76-17.98-33.34-37.28-33.32l-128.069.12c-20.699.021-37.461 16.82-37.439 37.521h0c.01 11.188 4.93 21.22 12.71 28.08l-76.34.068c-6.16.012-11.15 5-11.141 11.16.011 6.16 5 11.15 11.159 11.142l99.552-.092c6.159-.01 11.148-5 11.14-11.158 0-.602-.061-1.19-.15-1.771l96.182-.09c2.068 18.76 17.979 33.34 37.279 33.32l44.631-.04c20.699-.021 37.459-16.82 37.438-37.521h0c-.022-20.701-16.823-37.461-37.522-37.439z"
        />
        <g>
          <defs>
            <circle id="SVGID_17_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlinkHref="#SVGID_17_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_18_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1110.82 673.592l-126.75.119c-24.08.021-43.61-19.479-43.63-43.551h0c-.02-24.08 19.48-43.608 43.55-43.63l126.75-.12c24.08-.02 43.61 19.48 43.63 43.551h0c.03 24.069-19.47 43.609-43.55 43.631z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_19_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlinkHref="#SVGID_19_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_20_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1408.33 342.76l-161.682.148c-30.71.03-55.63-24.84-55.66-55.55h0c-.029-30.71 24.84-55.63 55.551-55.66l161.681-.148c30.71-.03 55.63 24.84 55.659 55.55h0c.041 30.71-24.84 55.63-55.549 55.66z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_21_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_22_">
            <use xlinkHref="#SVGID_21_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_22_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M859.44 720.2l-137.23.13c-14.42.012-26.108-11.658-26.129-26.08h0c-.011-14.42 11.659-26.108 26.079-26.13l137.23-.13c14.42-.01 26.11 11.66 26.13 26.08h0c.01 14.41-11.67 26.11-26.08 26.13z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_23_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_24_">
            <use xlinkHref="#SVGID_23_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_24_)"
            fill="#FFBF66"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1443.51 868.842l-173.771.17c-18.25.02-33.068-14.771-33.078-33.021h0c-.021-18.25 14.77-33.07 33.02-33.08l173.77-.17c18.25-.02 33.07 14.771 33.08 33.021h0c.019 18.248-14.771 33.058-33.021 33.08z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_25_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_26_">
            <use xlinkHref="#SVGID_25_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_26_)"
            fill="#FFAC59"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1288.48 718.53l-166.07.16c-31.54.03-57.14-25.521-57.17-57.06h0c-.03-31.54 25.521-57.142 57.06-57.17l166.07-.16c31.54-.03 57.141 25.52 57.17 57.06h0c.03 31.55-25.52 57.14-57.06 57.17z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_27_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_28_">
            <use xlinkHref="#SVGID_27_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_28_)"
            fill="#FFAC59"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1343.3 902.26l-200.12.188a56.869 56.869 0 00-4.971-18.119l15.221-.011c14.939-.01 27.029-12.13 27.02-27.069h0c-.01-14.939-12.13-27.029-27.068-27.021l-83.512.08c-12.81.01-23.528 8.938-26.31 20.899l-133.63.131c-31.54.029-57.09 25.63-57.061 57.17h0c0 .37.021.729.029 1.1-17.81 9.682-29.89 28.57-29.87 50.26h0c.03 31.54 25.632 57.092 57.17 57.062l463.212-.44c31.539-.029 57.09-25.63 57.06-57.17h0c-.03-31.55-25.63-57.09-57.17-57.06z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_29_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_30_">
            <use xlinkHref="#SVGID_29_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_30_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1255.74 741.16l-68.97.07c-13.1.01-23.73-10.6-23.74-23.7h0c-.01-13.1 10.6-23.729 23.7-23.739l68.97-.069c13.1-.011 23.73 10.6 23.74 23.698h0c.008 13.1-10.6 23.73-23.7 23.74z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_31_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_32_">
            <use xlinkHref="#SVGID_31_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_32_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1582.039 691.82l-201.449.19c-13.101.01-23.73-10.602-23.74-23.7h0c-.01-13.1 10.6-23.73 23.699-23.74l201.451-.188c13.1-.012 23.729 10.6 23.738 23.698h0c.012 13.1-10.598 23.73-23.699 23.74z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_33_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_34_">
            <use xlinkHref="#SVGID_33_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_34_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1242.91 520.35l-171.271.16c-10.182.01-18.439-8.23-18.45-18.41h0c-.01-10.181 8.229-18.44 18.41-18.45l171.271-.16c10.181-.01 18.439 8.23 18.449 18.41h0c.011 10.18-8.229 18.442-18.409 18.45z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_35_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_36_">
            <use xlinkHref="#SVGID_35_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_36_)"
            fill="#FF5640"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1055.6 914.42l-39.66.041c-10.18.011-18.439-8.229-18.45-18.409h0c-.01-10.182 8.23-18.44 18.41-18.45l39.66-.04c10.18-.01 18.44 8.229 18.45 18.41h0c.01 10.178-8.23 18.438-18.41 18.448z"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_37_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_38_">
            <use xlinkHref="#SVGID_37_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_38_)"
            fill="none"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M727.93 287.27h346.22"
          />
        </g>
        <g>
          <defs>
            <circle id="SVGID_39_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_40_">
            <use xlinkHref="#SVGID_39_" overflow="visible" />
          </clipPath>
          <g
            clipPath="url(#SVGID_40_)"
            fill="none"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <path strokeDasharray="5,50,10" d="M711.03 459.06h889.96" />
            <path strokeDasharray="30,10" d="M1089.619 392.66h537.701" />
            <path strokeDasharray="5,10,40" d="M1257.709 366.04h346.221" />
            <path strokeDasharray="50,50,10" d="M976.77 560.93h346.22" />
            <path
              strokeDasharray="30,10"
              d="M1375.52 615.06h346.22M475.48 513.83h346.218"
            />
            <path strokeDasharray="5,10" d="M727.93 737.97h346.22" />
            <path
              strokeDasharray="5,10,40"
              d="M914.75 703h139.66M686.01 609.08h139.66"
            />
            <path strokeDasharray="5,10" d="M715.94 624.711h139.65" />
            <path strokeDasharray="5,10,40" d="M861.408 519.802h139.662" />
            <path
              strokeDasharray="5,10"
              d="M929.6 541.132h139.66M1296.3 781.291h139.659"
            />
            <path
              strokeDasharray="5,50,10"
              d="M1222.59 760.802h327.96M1162.74 354.7h327.958M761.31 276.99h327.96"
            />
            <path strokeDasharray="5,10,40" d="M641.789 800.632H969.74" />
            <path strokeDasharray="5,10" d="M838.55 827.67h327.96" />
            <path strokeDasharray="5,50,10" d="M1187.94 886.87h327.96" />
            <path strokeDasharray="50,50,10" d="M1098.22 788.03h91.05" />
          </g>
        </g>
        <g>
          <defs>
            <circle id="SVGID_41_" cx={1116} cy={569.09} r={375.56} />
          </defs>
          <clipPath id="SVGID_42_">
            <use xlinkHref="#SVGID_41_" overflow="visible" />
          </clipPath>
          <circle
            opacity={0.25}
            clipPath="url(#SVGID_42_)"
            fill="#9E2977"
            cx={1336.19}
            cy={670.18}
            r={431.93}
          />
        </g>
        <g>
          <circle
            id="SVGID_155_"
            fill="none"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            cx={1116}
            cy={569.09}
            r={375.56}
          />
        </g>
        <circle
          fill="none"
          stroke="#FF8359"
          strokeWidth={2}
          strokeMiterlimit={10}
          cx={1116}
          cy={569.09}
          r={375.56}
        />
        <path
          fill="none"
          stroke="#FFEA80"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M740.43 569.45c-.2-207.42 167.79-375.72 375.21-375.92"
        />
        <g>
          <g fill="none" stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
            <path d="M794.72 818.9c-.8.24-1.602.48-2.392.72" />
            <path
              strokeDasharray="4.9724,9.9448"
              d="M782.81 822.49C457.51 920.132 215.34 961.22 178 919.052"
            />
            <path d="M175.17 914.98a17.5 17.5 0 01-1.01-2.28" />
          </g>
        </g>
        <g>
          <g fill="none" stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
            <path d="M2055.84 225.28c.29.78.52 1.58.699 2.4" />
            <path
              strokeDasharray="5.0474,10.0949"
              d="M2056.35 237.69c-10.53 47.04-147.359 134.77-357.97 235.53"
            />
            <path d="M1693.82 475.4c-.75.36-1.5.721-2.26 1.08" />
          </g>
        </g>
        <path
          fill="none"
          stroke="#2E3EB3"
          strokeWidth={2}
          strokeMiterlimit={10}
          d="M684.13 625.32c-337.562-75.09-567-159.09-558.45-213.05M2104.32 725.7c-8.569 54.102-254.04 62.98-599.75 29.761"
        />
        <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <circle fill="#000F80" cx={1795.19} cy={223.95} r={38.55} />
          <path
            fill="#2E3EB3"
            d="M1794.78 192.46a38.23 38.23 0 00-5.04-6.68c-4.899.7-9.75 2.352-14.27 5.04-18.3 10.89-24.3 34.55-13.421 52.84a38.25 38.25 0 005.041 6.682c4.898-.7 9.75-2.352 14.27-5.04 18.3-10.882 24.31-34.542 13.42-52.842z"
          />
        </g>
        <circle fill="#0086D4" cx={2040.92} cy={90.54} r={5.54} />
        <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <circle fill="#2E3EB3" cx={1829.69} cy={775.63} r={20.15} />
          <path
            fill="#000F80"
            d="M1816.61 779.89c-.52 4.91.77 9.58 3.33 13.38a19.948 19.948 0 007.62 2.4c11.069 1.18 20.989-6.841 22.17-17.91.521-4.91-.771-9.58-3.33-13.38a19.954 19.954 0 00-7.62-2.4c-11.071-1.178-21 6.84-22.17 17.91z"
          />
        </g>
        <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <circle fill="#2E3EB3" cx={527.448} cy={893.04} r={28.2} />
          <path
            fill="#000F80"
            d="M533.42 874.73c6.869-.73 13.408 1.08 18.72 4.65a28.1 28.1 0 013.36 10.67c1.648 15.49-9.57 29.38-25.062 31.028-6.87.73-13.41-1.079-18.72-4.648-1.771-3.2-2.95-6.8-3.36-10.67-1.649-15.49 9.572-29.378 25.062-31.03z"
          />
        </g>
        <g>
          <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M242.32 946.132h207.03l36.58-36.572V702.53"
            />
            <path
              fill="#2E3EB3"
              d="M485.93 894.882V687.85H278.9l-36.58 36.57v207.03h207.03z"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M242.32 926.26V711.11l36.58-36.569h207.03"
            />
          </g>
          <g strokeWidth={2} strokeMiterlimit={10}>
            <g fill="none" stroke="#0013A6">
              <path d="M261.8 890.382h203.278M261.8 869.882h203.278M261.8 849.39h203.278M261.8 828.9h203.278M261.8 808.4h203.278M261.8 787.91h203.278M261.8 767.41h203.278M261.8 746.92h203.278M261.8 726.43h203.278M446.24 706.57v204.37M425.75 706.57v204.37M405.25 706.57v204.37M384.76 706.57v204.37M364.27 706.57v204.37M343.77 706.57v204.37M323.28 706.57v204.37M302.789 706.57v204.37M282.29 706.57v204.37" />
            </g>
            <g
              id="energy"
              stroke="#00E5FF"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                id="bottom"
                fill="#9E2977"
                d="M395.26 881.72c-10.54 0-18.25-4.28-23.88-7.4-3.472-1.92-5.521-3.02-7.521-3.25-.74-.09-1.52-.13-2.3-.13-4.05 0-8.479 1.14-13.61 2.46-6.47 1.66-13.778 3.54-22.14 3.54-4.03 0-7.84-.43-11.65-1.31-26.72-6.16-35.948-28.342-35.72-45.472.28-21.18 12.37-31.87 30.44-41.398 15.13-7.98 20.01-15.83 20.608-18.171.41-1.579.79-4.311 1.17-6.949.852-5.971 1.91-13.38 4.78-20.11 5.46-12.79 16.1-20.12 29.18-20.12 7.16 0 24.842 2.53 34.36 25.98 4.31 10.63 6.5 24.64 6.5 41.63 0 3.03.19 4.771.351 5.729.949.25 2.301.529 3.449.779 2.312.49 4.91 1.03 7.67 1.91 12.602 4.01 21.87 15.42 24.19 29.771 2.649 16.35-4.07 32.148-17.98 42.27-9.467 6.881-18.597 10.232-27.897 10.241h0z"
              />
              <path
                id="middle"
                fill="#FB4176"
                d="M395.26 870.81c-7.721 0-13.5-3.2-18.602-6.03-3.92-2.17-7.3-4.05-11.528-4.55-1.16-.14-2.37-.21-3.57-.21-5.42 0-10.72 1.36-16.33 2.8-6.12 1.57-12.44 3.2-19.42 3.2-3.2 0-6.21-.34-9.21-1.03-20.38-4.7-27.45-21.01-27.27-34.7.188-14.238 6.398-22.29 24.629-31.898 16.739-8.83 24.449-18.761 26.091-25.102.55-2.159.97-5.06 1.398-8.13.802-5.6 1.7-11.949 4.011-17.358 5.021-11.75 14.149-13.5 19.149-13.5 6.15 0 17.48 2.488 24.25 19.18 3.78 9.33 5.7 21.95 5.7 37.53 0 14.56 3.648 15.318 12.46 17.18 2.149.45 4.37.92 6.6 1.63 8.66 2.76 15.08 10.851 16.74 21.12 1.98 12.2-3.12 24.06-13.63 31.71-7.53 5.488-14.558 8.158-21.468 8.158h0z"
              />
              <path
                id="top"
                fill="#FF8359"
                d="M300.24 830.44c.102-7.979 1.74-13.39 18.812-22.399 17.068-9.011 28.67-20.819 31.56-32.029 2.892-11.21 1.681-30.801 14.01-30.801 12.33 0 19.052 17.729 19.052 45.801 0 28.068 16.35 25.92 26.67 29.199 10.31 3.279 14.938 22.75 0 33.619-20.66 15.04-25.9-2.31-43.932-4.42-18.028-2.108-31.72 8.57-47.35 4.972-15.642-3.612-18.932-15.962-18.822-23.942z"
              />
            </g>
            <g
              id="target"
              fill="none"
              stroke="#00E5FF"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path id="vertical" d="M364.06 696.45V921.4" />
              <path id="horizontal" d="M476.539 808.93H251.59" />
            </g>
          </g>
        </g>
        <motion.g id="m7-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m7]}>
          <g id="m7-label">
            <path
              id="m7-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1182.59 858.47h-148.15v-48.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.431-10.892 24.32-24.32 24.32z"
            />
            <text
              id="m7-text"
              transform="translate(1077.217 842.68)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m7]}
            </text>
          </g>
          <g
            id="m7-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle
              id="m7-outer"
              fill="#FFF"
              cx={1028.469}
              cy={834.151}
              r={33}
            />
            <circle
              id="m7-inner"
              fill="#38E0B6"
              cx={1028.469}
              cy={834.151}
              r={20.53}
            />
            <path
              id="m7-pin"
              fill="#FB4176"
              d="M1028.389 765.131c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.211-22.79-22.791-22.79zm.08 31.61c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m7]}
            />
          </g>
        </motion.g>
        <motion.g id="m7-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m7]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1184.06 858.15h-148.15v-48.64h148.148c13.432 0 24.318 10.89 24.318 24.318h0c.003 13.442-10.886 24.322-24.316 24.322z"
          />
          <path fill="none" d="M1064.67 822.92h123.77v26.621h-123.77z" />
          <text
            transform="translate(1078.692 842.361)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m7]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={1028.948} cy={833.83} r={33} />
            <path
              fill="#2E3EB3"
              d="M1032.92 855.26h-7.95c-7 0-12.67-5.67-12.67-12.67v-11.71h33.28v11.71c.01 7.002-5.66 12.67-12.66 12.67z"
            />
            <path
              fill="none"
              d="M1040.869 830.882V821.2a9.068 9.068 0 00-9.069-9.068h-5.7c-5.01 0-9.07 4.06-9.07 9.068v9.682M1028.948 837.74v8.69"
            />
          </g>
        </motion.g>
        <motion.g id="m6-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m6]}>
          <g id="m6-label">
            <path
              id="m6-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1487.69 724.02h-148.15v-48.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.431-10.893 24.32-24.32 24.32z"
            />
            <text
              id="m6-text"
              transform="translate(1382.317 708.23)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m6]}
            </text>
          </g>
          <g
            id="m6-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle id="m6-outer" fill="#FFF" cx={1333.569} cy={699.7} r={33} />
            <circle
              id="m6-inner"
              fill="#38E0B6"
              cx={1333.569}
              cy={699.701}
              r={20.53}
            />
            <path
              id="m6-pin"
              fill="#FB4176"
              d="M1333.489 630.68c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.589-10.211-22.79-22.791-22.79zm.08 31.611c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m6]}
            />
          </g>
        </motion.g>
        <motion.g id="m6-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m6]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1488.24 724.03H1340.1v-48.64h148.15c13.43 0 24.318 10.89 24.318 24.32h0c-.008 13.43-10.888 24.32-24.328 24.32z"
          />
          <path fill="none" d="M1368.85 688.802h123.77v26.619h-123.77z" />
          <text
            transform="translate(1382.877 708.241)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m6]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={1333.129} cy={699.711} r={33} />
            <path
              fill="#2E3EB3"
              d="M1337.11 721.28h-7.949c-7 0-12.671-5.67-12.671-12.67V696.9h33.28v11.71c.01 7-5.67 12.67-12.66 12.67z"
            />
            <path
              fill="none"
              d="M1345.06 696.9v-9.68c0-5.01-4.06-9.07-9.069-9.07h-5.701a9.068 9.068 0 00-9.068 9.07v9.68M1333.129 703.77v8.68"
            />
          </g>
        </motion.g>
        <motion.g id="m5-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m5]}>
          <g id="m5-label">
            <path
              id="m5-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1065.266 654.99h-148.15v-48.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.43-10.892 24.32-24.32 24.32z"
            />
            <text
              id="m5-text"
              transform="translate(959.893 639.2)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m5]}
            </text>
          </g>
          <g
            id="m5-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle id="m5-outer" fill="#FFF" cx={911.145} cy={630.67} r={33} />
            <circle
              id="m5-inner"
              fill="#38E0B6"
              cx={911.145}
              cy={630.671}
              r={20.53}
            />
            <path
              id="m5-pin"
              fill="#FB4176"
              d="M911.065 561.65c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.211-22.79-22.791-22.79zm.08 31.61c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m5]}
              />
          </g>
        </motion.g>
        <motion.g id="m5-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m5]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1066.209 655.08H918.06v-48.64h148.149c13.431 0 24.319 10.89 24.319 24.32h0c.002 13.44-10.888 24.32-24.319 24.32z"
          />
          <path fill="none" d="M946.82 619.85h123.771v26.62H946.82z" />
          <text
            transform="translate(960.841 639.292)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m5]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={911.1} cy={630.76} r={33} />
            <path
              fill="#2E3EB3"
              d="M915.07 652.33h-7.95c-7 0-12.67-5.67-12.67-12.67v-11.71h33.28v11.71c.01 7-5.66 12.67-12.66 12.67z"
            />
            <path
              fill="none"
              d="M923.02 627.95v-9.68a9.07 9.07 0 00-9.07-9.07h-5.699a9.069 9.069 0 00-9.069 9.07v9.68M911.1 634.82v8.68"
            />
          </g>
        </motion.g>
        <motion.g id="m4-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m4]}>
          <g id="m4-label">
            <path
              id="m4-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1332.53 548.121h-148.15v-48.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.43-10.893 24.32-24.32 24.32z"
            />
            <text
              id="m4-text"
              transform="translate(1227.157 532.33)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m4]}
            </text>
          </g>
          <g
            id="m4-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle
              id="m4-outer"
              fill="#FFF"
              cx={1178.408}
              cy={523.801}
              r={33}
            />
            <circle
              id="m4-inner"
              fill="#38E0B6"
              cx={1178.408}
              cy={523.801}
              r={20.531}
            />
            <path
              id="m4-pin"
              fill="#FB4176"
              d="M1178.328 454.781c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.211-22.79-22.791-22.79zm.08 31.61c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m4]}
            />
          </g>
        </motion.g>
        <motion.g id="m4-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m4]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1332.89 548.4h-148.15v-48.64h148.15c13.43 0 24.319 10.89 24.319 24.319h0c0 13.431-10.889 24.321-24.319 24.321z"
          />
          <path fill="none" d="M1213.5 513.17h123.77v26.621H1213.5z" />
          <text
            transform="translate(1227.52 532.607)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m4]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={1177.78} cy={524.08} r={33} />
            <path
              fill="#2E3EB3"
              d="M1181.75 545.65h-7.95c-7 0-12.671-5.67-12.671-12.67v-11.71h33.281v11.71c.01 7-5.66 12.67-12.66 12.67z"
            />
            <path
              fill="none"
              d="M1189.698 521.27v-9.68c0-5.011-4.06-9.07-9.069-9.07h-5.699c-5.01 0-9.07 4.06-9.07 9.07v9.68M1177.78 528.131v8.689"
            />
          </g>
        </motion.g>
        <motion.g id="m3-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m3]}>
          <g id="m3-label">
            <path
              id="m3-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M970.74 489.281H822.59V440.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c.001 13.43-10.892 24.321-24.32 24.321z"
            />
            <text
              id="m3-text"
              transform="translate(865.368 473.49)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m3]}
            </text>
          </g>
          <g
            id="m3-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle id="m3-outer" fill="#FFF" cx={816.619} cy={464.96} r={33} />
            <circle
              id="m3-inner"
              fill="#38E0B6"
              cx={816.619}
              cy={464.96}
              r={20.53}
            />
            <path
              id="m3-pin"
              fill="#FB4176"
              d="M816.539 395.94c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.211-22.79-22.791-22.79zm.08 31.61c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m3]}
            />
          </g>
        </motion.g>
        <motion.g id="m3-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m3]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M971.879 489.382H823.73V440.74h148.149c13.431 0 24.319 10.891 24.319 24.32h0c0 13.44-10.888 24.322-24.319 24.322z"
          />
          <path fill="none" d="M852.48 454.15h123.771v26.62H852.48z" />
          <text
            transform="translate(866.508 473.592)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m3]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={816.76} cy={465.06} r={33} />
            <path
              fill="#2E3EB3"
              d="M820.74 486.632h-7.95c-7 0-12.67-5.671-12.67-12.671V462.25h33.28v11.711c.008 7-5.66 12.671-12.66 12.671z"
            />
            <path
              fill="none"
              d="M828.69 462.25v-9.68c0-5.01-4.06-9.07-9.07-9.07h-5.698a9.069 9.069 0 00-9.07 9.07v9.68M816.76 469.121v8.681"
            />
          </g>
        </motion.g>
        <motion.g id="m2-group" style={{ cursor: 'pointer' }} whileHover={hoverModule} onClick={handleClick} ref={moduleGroups[modules.m2]}>
          <g id="m2-label">
            <path
              id="m2-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1346.52 413.551h-148.15V364.91h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.43-10.892 24.321-24.32 24.321z"
            />
            <text
              id="m2-text"
              transform="translate(1241.147 397.76)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m2]}
            </text>
          </g>
          <g
            id="m2-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
          >
            <circle
              id="m2-outer"
              fill="#FFF"
              cx={1192.399}
              cy={389.231}
              r={33}
            />
            <circle
              id="m2-inner"
              fill="#38E0B6"
              cx={1192.399}
              cy={389.231}
              r={20.53}
            />
            <path
              id="m2-pin"
              fill="#FB4176"
              d="M1192.319 320.21c-12.59 0-22.789 10.2-22.789 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.211-22.79-22.791-22.79zm.08 31.611c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.262 4.59 10.262 10.26-4.592 10.26-10.262 10.26z"
              ref={pinRefs[modules.m2]}
            />
          </g>
        </motion.g>
        <motion.g
          whileHover={hoverModule}
          id="m2-group-lock"
          style={{ cursor: 'not-allowed' }}
          ref={moduleLockGroups[modules.m2]}
        >
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1346.82 413.1h-148.15v-48.64h148.148c13.432 0 24.32 10.892 24.32 24.32h0c.002 13.43-10.888 24.32-24.318 24.32z"
          />
          <path fill="none" d="M1227.42 377.87h123.77v26.62h-123.77z" />
          <text
            transform="translate(1241.45 397.31)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m2]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={1191.709} cy={388.78} r={33} />
            <path
              fill="#2E3EB3"
              d="M1195.869 410.19h-7.949c-7 0-12.67-5.67-12.67-12.67v-11.71h33.278v11.71c.002 7-5.668 12.67-12.659 12.67z"
            />
            <path
              fill="none"
              d="M1203.81 385.81v-9.682c0-5.01-4.06-9.068-9.069-9.068h-5.701a9.067 9.067 0 00-9.068 9.068v9.682M1191.89 392.67v8.69"
            />
          </g>
        </motion.g>
        <motion.g
          id="m1-group"
          ref={moduleGroups[modules.m1]}
          onClick={handleClick}
          whileHover={hoverModule}
          style={{ cursor: 'pointer' }}
          // onMouseOver={onActiveModuleHover}
          // onMouseOut={onActiveModuleOut}
        >
          <g id="m1-label">
            <path
              id="m1-label-bg"
              fill="#FFF"
              stroke="#000F80"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1134.02 338.72H985.87v-48.64h148.15c13.43 0 24.32 10.892 24.32 24.32h0c0 13.43-10.892 24.32-24.32 24.32z"
            />
            <text
              id="m1-text"
              transform="translate(1028.648 322.93)"
              fill="#000F80"
              fontFamily="'Kastelov'"
              fontSize={25.922}
            >
              {MAP_MODULE_NAMES[modules.m1]}
            </text>
          </g>
          <motion.g
            id="m1-target"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            initial="rest" whileHover="hover" animate="rest"
          >
            <circle id="m1-outer" fill="#FFF" cx={978.9} cy={314.4} r={33} />
            <circle
              id="m1-inner"
              fill="#38E0B6"
              cx={978.9}
              cy={314.4}
              r={20.53}
            />
            <path
              id="m1-pin"
              fill="#FB4176"
              d="M978.82 245.38c-12.59 0-22.79 10.2-22.79 22.79 0 15.642 13.4 36.392 19.65 45.21 1.529 2.16 4.73 2.15 6.25-.02 6.24-8.9 19.68-29.87 19.68-45.19 0-12.59-10.21-22.79-22.79-22.79zm.08 31.61c-5.67 0-10.26-4.59-10.26-10.26s4.59-10.26 10.26-10.26 10.261 4.59 10.261 10.26-4.591 10.26-10.261 10.26z"
              ref={pinRefs[modules.m1]}
              />
          </motion.g>
        </motion.g>
        <motion.g id="m1-group-lock" style={{ cursor: 'not-allowed' }} whileHover={hoverModule} ref={moduleLockGroups[modules.m1]}>
          <path
            fill="#4351BF"
            stroke="#000F80"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1134.015 338.37h-148.15v-48.64h148.148c13.432 0 24.32 10.892 24.32 24.32h0c.002 13.43-10.887 24.32-24.318 24.32z"
          />
          <path fill="none" d="M1014.615 303.14h123.77v26.62h-123.77z" />
          <text
            transform="translate(1028.645 322.58)"
            fill="#000F80"
            fontFamily="'Kastelov'"
            fontSize={25.922}
          >
            {MAP_MODULE_NAMES[modules.m1]}
          </text>
          <g stroke="#000F80" strokeWidth={2} strokeMiterlimit={10}>
            <circle fill="#4351BF" cx={978.905} cy={314.05} r={33} />
            <path
              fill="#2E3EB3"
              d="M983.065 335.46h-7.949c-7 0-12.67-5.67-12.67-12.67v-11.71h33.278v11.71c.001 7-5.669 12.67-12.659 12.67z"
            />
            <path
              fill="none"
              d="M991.005 311.08v-9.682c0-5.01-4.06-9.068-9.069-9.068h-5.701a9.067 9.067 0 00-9.068 9.068v9.682M979.085 317.94v8.69"
            />
          </g>
        </motion.g>
      </g>
      <g id="interfejs">
        <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M1689.369 568.03h272.221l36.57-36.569V324.43"
          />
          <path
            fill="#2E3EB3"
            d="M1998.16 516.78V309.75h-207.031l-36.569 36.57v207.03h207.03z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M1754.56 548.16V333.02l36.569-36.58h207.031"
          />
        </g>
        <g strokeMiterlimit={10}>
          <path
            fill="#000F80"
            stroke="#000F80"
            strokeWidth={2}
            d="M-3.81-107.49l-53 62.398v1222.82H2234.2l53-62.4V-107.49H-3.81zM2174.198 973.7l-47.17 47.17H56.19V96.54l47.17-47.17H2174.2l-.002 924.33h0z"
          />
          <path
            fill="none"
            stroke="#5061E6"
            strokeWidth={3}
            d="M56.19 524.08V96.54l47.17-47.17h351.12M2174.198 870.291V973.7l-47.168 47.17h-471.34M1913.16 49.37h261.038v403.99M1265.68 1020.87H56.19V893.41"
          />
          <path
            fill="none"
            stroke="#0972CF"
            strokeWidth={2}
            strokeDasharray="5,10"
            d="M56.19 556.65v314.5M484.948 49.37H1873.11M1286.8 1020.87h343.36M2174.198 477.7v360.75"
          />
        </g>
        <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M242.32 614.07h308.75l54.548-54.55V250.73"
          />
          <path
            fill="#2E3EB3"
            d="M605.618 544.7V235.91H296.83l-54.54 54.55v308.79h308.78z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M551.07 221.092H296.83l-54.54 54.548v308.79"
          />
          <g>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M242.29 221.092h308.78l54.548-54.54V140.96"
            />
            <path
              fill="#FFF"
              d="M605.618 151.73v-25.59H296.83l-54.54 54.55v25.59h308.78z"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M605.618 111.33H296.83l-54.54 54.54v25.59"
            />
          </g>
        </g>
        <path fill="none" d="M248.53 147.75h350.852v36.91H248.53z" />
        <text
          transform="translate(314.87 177.762)"
          fill="#0013A6"
          fontFamily="'Kastelov'"
          fontSize={40.011}
        >
          {"Planeta X09"}
        </text>
        <g
          stroke="#0013A6"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <g fill="#0FF">
            <path d="M350.61 409.39h-68.14c-6.312 0-11.42-5.11-11.42-11.42h0c0-6.312 5.108-11.42 11.42-11.42h68.14c6.312 0 11.42 5.108 11.42 11.42h0c-.01 6.31-5.122 11.42-11.42 11.42zM350.61 449.92h-68.14a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h68.14c6.312 0 11.42 5.11 11.42 11.42h0c-.01 6.31-5.122 11.42-11.42 11.42zM350.61 490.45h-68.14a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h68.14c6.312 0 11.42 5.11 11.42 11.42h0c-.01 6.3-5.122 11.42-11.42 11.42zM350.61 530.97h-68.14c-6.312 0-11.42-5.11-11.42-11.42h0c0-6.312 5.108-11.42 11.42-11.42h68.14c6.312 0 11.42 5.108 11.42 11.42h0c-.01 6.31-5.122 11.42-11.42 11.42zM350.61 571.5h-68.14a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h68.14c6.312 0 11.42 5.11 11.42 11.42h0c-.01 6.31-5.122 11.42-11.42 11.42z" />
          </g>
          <g fill="#2E3EB3">
            <path d="M333.078 409.39H282.46c-6.312 0-11.42-5.11-11.42-11.42h0c0-6.312 5.108-11.42 11.42-11.42h50.618c6.312 0 11.422 5.108 11.422 11.42h0c-.01 6.31-5.12 11.42-11.422 11.42zM318.25 449.92h-35.79a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h35.79c6.31 0 11.42 5.11 11.42 11.42h0c0 6.31-5.11 11.42-11.42 11.42zM322.709 490.45H282.46a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h40.249c6.311 0 11.421 5.11 11.421 11.42h0c-.012 6.3-5.12 11.42-11.421 11.42zM342.28 530.97h-59.82c-6.312 0-11.42-5.11-11.42-11.42h0c0-6.312 5.108-11.42 11.42-11.42h59.818c6.312 0 11.42 5.108 11.42 11.42h0c0 6.31-5.108 11.42-11.418 11.42zM308.51 571.5h-26.05a11.414 11.414 0 01-11.42-11.42h0c0-6.31 5.108-11.42 11.42-11.42h26.05c6.31 0 11.42 5.11 11.42 11.42h0c0 6.31-5.11 11.42-11.42 11.42z" />
          </g>
        </g>
        <g strokeWidth={2} strokeMiterlimit={10}>
          <path
            fill="#0FF"
            stroke="#2E3EB3"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M579.34 293.47V262.2H315.23l-46.66 46.65v31.28h264.11z"
          />
          <g
            fill="none"
            stroke="#00E5FF"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M283.61 310.14v21.36M310.92 284.62v46.88M338.23 270.82v60.68M365.53 270.82v60.68M392.84 270.82v60.68M420.15 270.82v60.68M447.459 270.82v60.68M474.77 270.82v60.68M502.078 270.82v60.68M529.38 270.82v60.68M556.69 270.82v30.34M276.78 316.802h261.48M325.34 286.73h245.119" />
          </g>
          <path
            id="chart"
            fill="none"
            stroke="#2E3EB3"
            d="M295.67 301.87l16.06-11.51 13.218 24.68 12.591-15.76 10.631 12.17 7.948-24.33L371 322.83l23.19-31.488 5.56 9 22.99-16.79 17.25 29.348 18.29-23.68 9.048 20.78 11.83-10.72 16.352 13.62 19.89-18.16 5.889 15.26 28.941-18.658 4.559 7.938"
          />
        </g>
        <g stroke="#0013A6" strokeWidth={2} strokeMiterlimit={10}>
          <path fill="#FFAC59" d="M387.658 378.91h14.2v30.48h-14.2z" />
          <path fill="#FB4176" d="M412.76 389.38h14.199v20.021H412.76z" />
          <path
            fill="#FFAC59"
            d="M437.86 378.91h14.2v30.48h-14.2zM462.959 372.592h14.199v36.8h-14.199zM488.06 378.91h14.2v30.48h-14.2zM513.158 360.54h14.2v48.852h-14.2zM538.26 378.91h14.199v30.48H538.26z"
          />
          <path fill="#FB4176" d="M563.36 386.82h14.2v22.58h-14.2z" />
          <g>
            <path fill="none" d="M387.658 479.08h14.2v10.012h-14.2z" />
            <path
              fill="#0FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M412.76 469.08h14.199v20.021H412.76z"
            />
            <path fill="none" d="M437.86 469.08h14.2v20.021h-14.2z" />
            <path
              fill="#0FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M462.959 452.291h14.199v36.801h-14.199z"
            />
            <path fill="none" d="M488.06 458.61h14.2v30.48h-14.2z" />
            <path
              fill="#0FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M513.158 476.07h14.2v13.03h-14.2z"
            />
            <path fill="none" d="M538.26 474.2h14.199v14.892H538.26z" />
            <path
              fill="#0FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M563.36 482.39h14.2v6.71h-14.2z"
            />
          </g>
          <g>
            <path
              fill="#FFAC59"
              d="M387.658 538.31h14.2v30.48h-14.2zM412.76 548.78h14.199v20.021H412.76zM437.86 538.31h14.2v30.48h-14.2zM462.959 542.45h14.199v26.341h-14.199z"
            />
            <path
              fill="#0FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M488.06 538.31h14.2v30.48h-14.2zM513.158 548.66h14.2v20.131h-14.2zM538.26 538.31h14.199v30.48H538.26z"
            />
          </g>
        </g>
        <g>
          <g stroke="#2E3EB3" strokeWidth={2} strokeMiterlimit={10}>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1634.82 961.01h308.79l54.55-54.549V597.67"
            />
            <path
              fill="#2E3EB3"
              d="M1998.16 891.64V582.85h-308.791l-54.549 54.55v308.79h308.79z"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1689.369 568.03l-54.549 54.55v308.79"
            />
          </g>
          <g
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
          >
            <path
              fill="#000F80"
              stroke="#000F80"
              d="M1971.879 640.41v-31.278H1707.76l-46.65 46.659v31.269h264.11z"
            />
            <g fill="none" stroke="#0013A6">
              <path d="M1672.33 658.08v21.36M1699.64 632.552v46.888M1726.948 618.76v60.68M1754.25 618.76v60.68M1781.56 618.76v60.68M1808.869 618.76v60.68M1836.18 618.76v60.68M1863.49 618.76v60.68M1890.8 618.76v60.68M1918.1 618.76v60.68M1945.41 618.76v30.34M1682.5 649.1h270.52" />
            </g>
          </g>
          <motion.path
            fill="none"
            stroke="#FF8359"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M1693.43 638.1c23.77 0 23.77 20 47.55 20 23.771 0 23.771-20 47.55-20 23.771 0 23.771 20 47.551 20s23.779-20 47.551-20 23.779 20 47.55 20"
            id="sin"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.8, repeat: Infinity, delay: 2 }}
            />
          <g stroke="#0013A6" strokeWidth={2} strokeMiterlimit={10}>
            <path
              fill="#FFAC59"
              d="M1685.03 740.08v26.79c0 6.312-5.109 11.421-11.42 11.421h0c-6.311 0-11.42-5.109-11.42-11.421v-26.79c0-6.31 5.109-11.42 11.42-11.42h0c6.3 0 11.42 5.11 11.42 11.42zM1725.55 719.4v68.141c0 6.311-5.11 11.42-11.421 11.42h0c-6.311 0-11.42-5.109-11.42-11.42V719.4c0-6.31 5.109-11.42 11.42-11.42h0c6.311 0 11.421 5.112 11.421 11.42z"
            />
            <path
              fill="#FB4176"
              d="M1766.08 748.87v9.2c0 6.312-5.11 11.42-11.422 11.42h0c-6.31 0-11.42-5.108-11.42-11.42v-9.2c0-6.31 5.11-11.42 11.42-11.42h0c6.312 0 11.422 5.12 11.422 11.42z"
            />
            <path
              fill="#FFAC59"
              d="M1806.61 738.45v30.05c0 6.312-5.109 11.42-11.42 11.42h0c-6.311 0-11.42-5.108-11.42-11.42v-30.05c0-6.31 5.109-11.42 11.42-11.42h0c6.3 0 11.42 5.11 11.42 11.42zM1847.129 730.64v45.67c0 6.31-5.109 11.42-11.42 11.42h0c-6.311 0-11.42-5.11-11.42-11.42v-45.67c0-6.311 5.109-11.42 11.42-11.42h0c6.311 0 11.42 5.11 11.42 11.42zM1887.66 745.42v16.11c0 6.312-5.11 11.42-11.422 11.42h0c-6.31 0-11.42-5.108-11.42-11.42v-16.11c0-6.31 5.11-11.42 11.42-11.42h0c6.312 0 11.422 5.11 11.422 11.42z"
            />
            <path
              fill="#FB4176"
              d="M1928.19 753.47h0c0 3.38-2.74 6.108-6.109 6.108h-10.609a6.107 6.107 0 01-6.11-6.108h0a6.11 6.11 0 016.11-6.11h10.609a6.117 6.117 0 016.109 6.11z"
            />
            <path
              fill="#FFAC59"
              d="M1968.709 745.32v16.3c0 6.312-5.109 11.421-11.42 11.421h0c-6.311 0-11.421-5.109-11.421-11.421v-16.3c0-6.31 5.11-11.42 11.421-11.42h0c6.311.01 11.42 5.12 11.42 11.42z"
            />
            <g strokeLinecap="round" strokeLinejoin="round">
              <path
                fill="#000F80"
                d="M1685.03 839.98v50.62c0 6.31-5.109 11.42-11.42 11.42h0c-6.311 0-11.42-5.11-11.42-11.42v-50.62c0-6.311 5.109-11.42 11.42-11.42h0c6.3 0 11.42 5.11 11.42 11.42z"
              />
              <path
                fill="#FF8359"
                d="M1725.55 854.802v35.79c0 6.31-5.11 11.42-11.421 11.42h0c-6.311 0-11.42-5.11-11.42-11.42v-35.79c0-6.312 5.109-11.42 11.42-11.42h0c6.311 0 11.421 5.108 11.421 11.42z"
              />
              <path
                fill="#000F80"
                d="M1766.08 850.342v40.25c0 6.31-5.11 11.42-11.422 11.42h0c-6.31 0-11.42-5.11-11.42-11.42v-40.25c0-6.312 5.11-11.42 11.42-11.42h0c6.312.008 11.422 5.119 11.422 11.42z"
              />
              <path
                fill="#FF8359"
                d="M1806.61 830.77v59.82c0 6.31-5.109 11.42-11.42 11.42h0c-6.311 0-11.42-5.11-11.42-11.42v-59.82c0-6.31 5.109-11.42 11.42-11.42h0c6.3 0 11.42 5.111 11.42 11.42z"
              />
              <path
                fill="#000F80"
                d="M1847.129 864.541v26.051c0 6.31-5.109 11.42-11.42 11.42h0c-6.311 0-11.42-5.11-11.42-11.42v-26.051c0-6.311 5.109-11.421 11.42-11.421h0c6.311 0 11.42 5.11 11.42 11.421z"
              />
            </g>
            <path
              fill="none"
              d="M1860.369 808.53h91.121M1860.369 822.51h70.811M1860.369 836.5h91.121M1860.369 850.48h57.329M1860.369 864.47h91.121M1860.369 878.45h91.121M1880.3 892.11h51.26M1880.3 899.78h36.47M1880.3 907.45h51.26"
            />
            <g fill="none">
              <path d="M1664.92 911.75h17.58M1705.35 911.75h17.57M1745.77 911.75h17.58M1786.19 911.75h17.58M1826.619 911.75h17.571" />
            </g>
            <g fill="none">
              <path d="M1664.92 808.57h17.58M1702.24 808.57h23.79M1745.77 808.57h17.58M1788.82 808.57h12.32M1826.619 808.57h17.571M1907.98 720.19h17.58M1953.02 720.19h8.55" />
            </g>
          </g>
        </g>
        <g>
          <g id="planet-small">
            <defs>
              <circle id="SVGID_43_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_44_">
              <use xlinkHref="#SVGID_43_" overflow="visible" />
            </clipPath>
            <circle
              clipPath="url(#SVGID_44_)"
              fill="#2E3EB3"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              cx={1877.75}
              cy={430.04}
              r={102.89}
            />
            <defs>
              <circle id="SVGID_45_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_46_">
              <use xlinkHref="#SVGID_45_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_46_)"
              fill="#2E3EB3"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1879.379 385.52l-117.579.11c-5.021 0-9.101-4.06-9.101-9.078h0c0-5.021 4.061-9.102 9.079-9.102l117.58-.108c5.021 0 9.101 4.06 9.101 9.078h0c.011 5.02-4.059 9.09-9.08 9.1z"
            />
            <defs>
              <circle id="SVGID_47_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_48_">
              <use xlinkHref="#SVGID_47_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_48_)"
              fill="#FFAC59"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M2080.14 399.18l-187.19.18a4.39 4.39 0 01-4.39-4.39h0c0-2.42 1.96-4.392 4.39-4.392l187.19-.18a4.39 4.39 0 014.39 4.39h0a4.396 4.396 0 01-4.39 4.392z"
            />
            <defs>
              <circle id="SVGID_49_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_50_">
              <use xlinkHref="#SVGID_49_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_50_)"
              fill="#FB4176"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1826.67 434.48l-80.11.08a4.39 4.39 0 01-4.39-4.391h0a4.39 4.39 0 014.39-4.391l80.11-.08a4.39 4.39 0 014.39 4.392h0c0 2.42-1.97 4.39-4.39 4.39z"
            />
            <defs>
              <circle id="SVGID_51_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_52_">
              <use xlinkHref="#SVGID_51_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_52_)"
              fill="#FFAC59"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1849.28 482.51l-75.08.069a4.389 4.389 0 01-4.39-4.391h0a4.39 4.39 0 014.39-4.39l75.08-.07a4.39 4.39 0 014.391 4.391h0a4.391 4.391 0 01-4.391 4.391z"
            />
            <defs>
              <circle id="SVGID_53_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_54_">
              <use xlinkHref="#SVGID_53_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_54_)"
              fill="#2E3EB3"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1968.379 437.211l-19.649.021a5.757 5.757 0 01-5.76-5.74h0c0-3.18 2.569-5.75 5.739-5.76l19.649-.021a5.757 5.757 0 015.761 5.739h0a5.737 5.737 0 01-5.74 5.761z"
            />
            <defs>
              <circle id="SVGID_55_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_56_">
              <use xlinkHref="#SVGID_55_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_56_)"
              fill="#2E3EB3"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1948.02 416.37l-10.109.01a2.959 2.959 0 01-2.961-2.96h0a2.96 2.96 0 012.961-2.96l10.109-.01a2.96 2.96 0 012.96 2.96h0a2.96 2.96 0 01-2.96 2.96z"
            />
            <defs>
              <circle id="SVGID_57_" cx={1877.75} cy={430.04} r={89.47} />
            </defs>
            <clipPath id="SVGID_58_">
              <use xlinkHref="#SVGID_57_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_58_)"
              fill="#33C9FF"
              stroke="#0013A6"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M1892.78 345.53l-5.279.01c-.489-4.47-4.279-7.938-8.881-7.938l-30.51.028c-4.93 0-8.92 4.012-8.92 8.94h0c0 2.67 1.17 5.06 3.03 6.69l-18.19.02c-1.47 0-2.66 1.19-2.65 2.66 0 1.47 1.19 2.66 2.66 2.65l23.711-.021c1.471 0 2.66-1.19 2.649-2.66 0-.14-.01-.28-.04-.42l22.91-.02a8.927 8.927 0 008.88 7.938l10.63-.01c4.931 0 8.92-4.01 8.92-8.94h0c.009-4.937-3.991-8.927-8.92-8.927z"
            />
            <g>
              <defs>
                <circle id="SVGID_59_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_60_">
                <use xlinkHref="#SVGID_59_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_60_)"
                fill="#FB4176"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1876.52 454.93l-30.189.03c-5.739.01-10.391-4.642-10.391-10.37h0c-.01-5.74 4.641-10.392 10.37-10.392l30.19-.029c5.738-.011 10.39 4.641 10.39 10.371h0c.01 5.73-4.64 10.39-10.37 10.39z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_61_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_62_">
                <use xlinkHref="#SVGID_61_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_62_)"
                fill="#2E3EB3"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1947.39 376.13l-38.521.04c-7.319.012-13.25-5.92-13.261-13.229h0c-.01-7.32 5.92-13.25 13.229-13.26l38.511-.04c7.319-.012 13.25 5.92 13.26 13.229h0c.021 7.31-5.91 13.25-13.218 13.26z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_63_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_64_">
                <use xlinkHref="#SVGID_63_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_64_)"
                fill="#FB4176"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1819 461.6l-32.69.03c-3.431 0-6.22-2.78-6.22-6.211h0c0-3.431 2.779-6.221 6.21-6.221l32.688-.029c3.432 0 6.221 2.779 6.221 6.211h0a6.204 6.204 0 01-6.209 6.22z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_65_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_66_">
                <use xlinkHref="#SVGID_65_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_66_)"
                fill="#FFBF66"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1955.77 501.45l-41.4.04c-4.35 0-7.88-3.52-7.88-7.869h0c0-4.351 3.521-7.881 7.87-7.881l41.4-.04c4.35 0 7.88 3.521 7.88 7.87h0c0 4.34-3.521 7.87-7.87 7.88z"
              />
            </g>
            <g id="small-planet-area-3">
              <g id="small-planet-area1">
                <defs>
                  <circle id="SVGID_67_" cx={1877.75} cy={430.04} r={89.47} />
                </defs>
                <clipPath id="SVGID_68_">
                  <use xlinkHref="#SVGID_67_" overflow="visible" />
                </clipPath>
                <path
                  clipPath="url(#SVGID_68_)"
                  fill="#FB4176"
                  stroke="#0013A6"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  d="M1912.64 461.83l-27.07.03c-5.14 0-9.31-4.16-9.319-9.3h0c0-5.141 4.16-9.311 9.301-9.32l27.068-.029c5.142 0 9.312 4.16 9.32 9.301h0c.008 5.138-4.16 9.308-9.3 9.318z"
                />
              </g>
            </g>
            <g id="small-planet-area2">
              <defs>
                <circle id="SVGID_69_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_70_">
                <use xlinkHref="#SVGID_69_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_70_)"
                fill="#FB4176"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1928.02 432.461l-20.63.021c-3.92 0-7.101-3.17-7.101-7.09h0c0-3.92 3.17-7.102 7.09-7.102l20.631-.02c3.92 0 7.1 3.17 7.1 7.09h0c0 3.92-3.17 7.101-7.09 7.101z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_71_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_72_">
                <use xlinkHref="#SVGID_71_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_72_)"
                fill="#FFAC59"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1813.789 408.18l-27.311.028c-5.189 0-9.399-4.198-9.399-9.38h0c-.011-5.188 4.199-9.398 9.38-9.398l27.311-.03c5.189 0 9.4 4.2 9.4 9.38h0c.01 5.18-4.19 9.39-9.381 9.4z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_73_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_74_">
                <use xlinkHref="#SVGID_73_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_74_)"
                fill="#FFAC59"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1963.539 477.69l-19.949.021a6.87 6.87 0 01-6.87-6.851h0a6.87 6.87 0 016.85-6.87l19.95-.02c3.79 0 6.859 3.07 6.87 6.85h0c.01 3.79-3.06 6.86-6.851 6.87z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_75_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_76_">
                <use xlinkHref="#SVGID_75_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_76_)"
                fill="#33C9FF"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1931.89 509.41l-47.67.051a13.872 13.872 0 00-1.181-4.319h3.631c3.56 0 6.438-2.892 6.438-6.45h0c0-3.561-2.892-6.439-6.45-6.439l-19.89.021c-3.052 0-5.601 2.13-6.271 4.979l-31.83.029c-7.511.012-13.601 6.102-13.59 13.62h0c0 .09.01.17.01.26-4.239 2.312-7.119 6.812-7.119 11.972h0c.01 7.51 6.1 13.6 13.619 13.59l110.341-.11c7.51-.01 13.6-6.1 13.59-13.62h0c-.008-7.514-6.108-13.594-13.628-13.584z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_77_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_78_">
                <use xlinkHref="#SVGID_77_" overflow="visible" />
              </clipPath>
              <motion.path
                whileHover={{
                  scale: 1.4,
                  transition: { duration: 1 },
                }}
                clipPath="url(#SVGID_78_)"
                fill="#FFAC59"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1921.81 467.552l-16.431.02a5.659 5.659 0 01-5.659-5.649h0a5.659 5.659 0 015.649-5.659l16.431-.021a5.659 5.659 0 015.659 5.65h0a5.653 5.653 0 01-5.649 5.659z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_79_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_80_">
                <use xlinkHref="#SVGID_79_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_80_)"
                fill="#FB4176"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1994.4 459.28l-47.989.05a5.659 5.659 0 01-5.66-5.648h0a5.66 5.66 0 015.649-5.66l47.99-.05a5.66 5.66 0 015.66 5.649h0a5.665 5.665 0 01-5.65 5.659z"
              />
            </g>
            <g display="none">
              <defs>
                <circle id="SVGID_81_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_82_">
                <use xlinkHref="#SVGID_81_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_82_)"
                fill="#2E3EB3"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1907.98 418.43l-40.8.04a4.39 4.39 0 01-4.391-4.392h0a4.39 4.39 0 014.391-4.39l40.8-.04a4.39 4.39 0 014.39 4.39h0a4.39 4.39 0 01-4.39 4.392z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_83_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_84_">
                <use xlinkHref="#SVGID_83_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_84_)"
                fill="#2E3EB3"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                d="M1863.36 512.302l-9.449.01a4.39 4.39 0 01-4.391-4.392h0a4.39 4.39 0 014.391-4.39l9.449-.01a4.39 4.39 0 014.391 4.39h0a4.393 4.393 0 01-4.391 4.392z"
              />
            </g>
            <g>
              <defs>
                <circle id="SVGID_85_" cx={1877.75} cy={430.04} r={89.47} />
              </defs>
              <clipPath id="SVGID_86_">
                <use xlinkHref="#SVGID_85_" overflow="visible" />
              </clipPath>
              <g
                clipPath="url(#SVGID_86_)"
                fill="none"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
              >
                <path strokeDasharray="5,50,10" d="M1781.28 403.83h212" />
                <path strokeDasharray="5,10,40" d="M1911.51 381.67h82.47" />
                <path strokeDasharray="50,50,10" d="M1844.58 428.1h82.48" />
                <path
                  strokeDasharray="30,10"
                  d="M1939.57 442.01h82.48M1725.17 416.88h82.47"
                />
                <path strokeDasharray="5,10" d="M1785.3 470.27h82.48" />
                <path
                  strokeDasharray="5,10,40"
                  d="M1829.81 461.94h33.27M1775.32 439.57h33.27"
                />
                <path strokeDasharray="5,10" d="M1782.448 443.291h33.272" />
                <path strokeDasharray="5,10,40" d="M1817.1 418.302h33.269" />
                <path
                  strokeDasharray="5,10"
                  d="M1833.35 423.382h33.26M1920.698 480.592h33.272"
                />
                <path strokeDasharray="5,50,10" d="M1903.14 475.711h78.13" />
                <path strokeDasharray="5,10,40" d="M1767.55 488.132h78.12" />
                <path strokeDasharray="5,50,10" d="M1894.89 505.74h78.12" />
                <path strokeDasharray="50,50,10" d="M1873.51 482.2h21.688" />
              </g>
            </g>
            <g>
              <circle
                id="SVGID_165_"
                fill="none"
                stroke="#0013A6"
                strokeWidth={2}
                strokeMiterlimit={10}
                cx={1877.75}
                cy={430.04}
                r={89.47}
              />
            </g>
          </g>
          <circle
            fill="none"
            stroke="#0013A6"
            strokeWidth={2}
            strokeMiterlimit={10}
            cx={1877.75}
            cy={430.04}
            r={89.47}
          />
          <g fill="none" stroke="#0013A6" strokeWidth={2} strokeMiterlimit={10}>
            <path d="M1947.52 321.75h37.01M1947.52 331.04h21.15M1947.52 340.33h37.01M1947.52 349.63h28.09M1947.52 358.92h22.88M1967.209 487.47l-38.33 38.332M1968.67 497.66h14.539M1961.948 505.132h14.532M1954.22 512.592h14.53M1946.49 520.052h14.53M1937.76 527.51h14.54M1777.56 427.58h-8.7v-79.17h40.29M1777.56 358.57h20.43M1777.56 367.24h16.43M1777.56 375.92h10.21M1770.369 509.4h36.801M1770.369 517.86h16.321M1770.369 526.31h36.801M1770.369 534.77h27.621" />
          </g>
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(Map)
export default MemoSvgComponent
