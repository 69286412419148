export const colors = {
  red: '#FA4075',
  turq: '#00ffff',
  turq1: '#33c9ff',
  lemon: '#ecff8c',
  yellow: '#fff387',
  orange1: '#ff8359',
  orange2: '#ff5640',
  royalBlue1: '#0013a6',
  royalBlue2: '#000f80',
  blueViolet: '#2e3eb3',
  pink: '#fb4176',
  gray: '#616161',
  green: '#186526',
};
