import { Icon } from 'semantic-ui-react';
import { IconWrapper } from './Config';
import { observer } from 'mobx-react';

export const SoundConfig = observer(({ configStore }) => {
  const onSoundChange = () => {
    configStore.setIsSoundOn(!configStore.isSoundOn );
  };

  return (
    <IconWrapper>
      <Icon onClick={onSoundChange} size="big" name={configStore.isSoundOn ? 'volume up' : 'volume off'} />
    </IconWrapper>
  );
});
