import styled from 'styled-components';
import { colors } from 'Constants/colors';

export const Card = styled.div`
  background-color: white;
  color: ${colors.royalBlue2};
  border: 3px solid ${colors.royalBlue2};
  box-shadow: 3px 3px ${colors.royalBlue2};
  border-radius: 30px;
  padding: 30px;
  width: ${(props) => props.maxWidth && '100%'};
  margin-bottom: ${(props) => props.marginBottom}px;
  max-height: 90%;
  overflow-y: ${(props) => props.overflow || 'auto'};
  position: relative;
  overflow-x: ${(props) => props.overflowX};
  max-width: ${(props) =>
    props.maxWidth && typeof props.maxWidth === 'string' ? props.maxWidth : `${props.maxWidth}px`};
`;
