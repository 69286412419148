import firebase from 'firebase/app';
import styled from 'styled-components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home } from 'Pages/Home';
import { Admin } from 'Pages/Admin';
import { Login } from 'Pages/Login';
import { Navbar } from 'Components/Navbar';
import { PrivateRoute } from 'Components/PrivateRoute';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks/useStores';
import { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Registration } from './Registration';
import { Dashboard } from './Dashboard';
import { routes } from '../Constants/routes';
import { PasswordReset } from './PasswordReset';
import { ConfirmEmail } from './ConfirmEmail';

const RouteWrapper = styled.div`
  height: ${(props) => (props.authenticated ? 'calc(100% - 50px)' : '100%')};
`;

export const Router = observer(() => {
  const { userStore } = useStores();
  const [userLoaded, setUserLoaded] = useState(false);
  const [userNotVerified, setUserNotVerified] = useState(false);

  const authenticated = userStore.user?.uid;

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        const token = await firebase.auth().currentUser.getIdTokenResult();
        userStore.setUser(user, token.claims.role === 'admin');
      } else if (user && !user.emailVerified) {
        setUserNotVerified(true)
      } else {
        console.log('no user');
      }
      setUserLoaded(true);
    });
  }, [userStore]);

  if (userNotVerified) {
    return (
      <ConfirmEmail/>
    )
  } else {
    return (
      <BrowserRouter>
        {userLoaded ? (
          <>
            {authenticated && <Navbar />}
            <RouteWrapper authenticated={authenticated}>
              <Switch>
                <PrivateRoute allow={authenticated} exact path="/" component={Home} />
                <PrivateRoute allow={authenticated} exact path={`${routes.DASHBOARD}`} component={Dashboard} />
                <PrivateRoute allow={authenticated && userStore.isAdmin} exact path={`${routes.ADMIN}`} component={Admin} />
                <Route exact path={`${routes.LOGIN}`}>
                  <Login />
                </Route>
                <Route exact path={`${routes.REGISTRATION}`}>
                  <Registration />
                </Route>
                <Route exact path={`${routes.RESET_PASSWORD}`}>
                  <PasswordReset />
                </Route>
              </Switch>
            </RouteWrapper>
          </>
        ) : (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
      </BrowserRouter>
    );
  }
});
