import { memo } from 'react';
import styled from 'styled-components';
import { Options } from './Options';
import { TextBox } from './TextBox';

export const ChatWidget = memo(({ id, text, npcName, options, changeSlide }) => {
  return (
    <OverflowContainer>
      <ChatWrapper options={options}>
        {text ? (
          <TextBox maxWidth={'80%'} minWidth={'60%'} padding="40px 24px" radius={32}>
            {npcName && (
              <NameWrapper>
                <TextBox>{npcName}</TextBox>
              </NameWrapper>
            )}
            {text}
            <Options id={id} options={options} changeSlide={changeSlide} />
          </TextBox>
        ) : (
          <Options options={options} changeSlide={changeSlide} />
        )}
      </ChatWrapper>
    </OverflowContainer>
  );
});

const OverflowContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const NameWrapper = styled.div`
  position: absolute;
  top: -40px;
  left: 32px;
`;

const ChatWrapper = styled.div`
  width: 100%;
  transform: translate(-50%, -50%);
  top: ${(props) =>
    props.options && props.options.length <= 1 ? '80%' : props.options && props.options.length <= 2 ? '75%' : '70%'};
  left: 50%;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
`;
