import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Slide } from './Slide';
import { useStores } from 'Hooks/useStores';
import { useFirebaseStorage } from 'Hooks/useFirebaseStorage';
import { useApi } from '../../Hooks/useApi';

export const Slideshow = observer(({ loading, setLoading }) => {
  const [images, setImages] = useState([]);

  const { gameStore } = useStores();
  const { getImageUrl } = useFirebaseStorage();
  const { updateUserGameState, updateUserModuleProgress } = useApi();

  useEffect(() => {
    if (gameStore.currentModuleSlides.length) {
      (async () => {
        setLoading('Ładowanie modułu');
        const currentImageId = gameStore.getCurrentSlide()?.imageId;
        const currentUrl = await getImageUrl(gameStore.currentModule, currentImageId);
        setImages((images) => [...images, { src: currentUrl, imageId: currentImageId }]);
        const slidesCount = gameStore.currentModuleSlides?.length;
        let allImages = [];
        gameStore.currentModuleSlides.forEach(async (slide, index) => {
          const url = await getImageUrl(gameStore.currentModule, slide.imageId);
          if (slidesCount - 1=== allImages.length) {
            setLoading(false);
            setImages((newImages) => [...newImages, ...allImages]);
          }
          allImages = [...allImages, { src: url, imageId: slide.imageId }];
        });
      })();
    }
  }, [gameStore.currentModuleSlides, gameStore.currentModule]);

  const changeSlide = useCallback(
    ({ nextId, end }) => {
      if (end) {
        (async () => {
          await updateUserModuleProgress({ module: gameStore.currentModule, finished: true });
          gameStore.setCurrentModuleFinished();
        })();
      } else {
        // try to append step to user module path
        updateUserGameState(gameStore.currentModule, nextId).then((resp) => {
          if (resp === 205) {
            //todo maybe some notification for user?
            window.location.reload();
          }
        });
        gameStore.setSlide(getNextSlide(nextId));
      }
    },
    [gameStore, gameStore.currentModule]
  );

  const getNextSlide = (nextId) => {
    if (gameStore.currentModule === 'm1') {
      // check if user completed all branches
      const userPath = Object.values(gameStore.currentUserPath);
      const aBranchFinal = [26, 31, 35, 39];
      const bBranchFinal = [72];
      const cBranchFinal = [81, 82, 86];

      const finalPathBeginning = 88;

      if (
        userPath.some((ele) => aBranchFinal.includes(ele)) &&
        userPath.some((ele) => bBranchFinal.includes(ele)) &&
        userPath.some((ele) => cBranchFinal.includes(ele)) &&
        nextId < 87
      ) {
        return finalPathBeginning;
      }
    }
    return nextId;
  };

  const data = gameStore.getCurrentSlide();

  return (
    <Slides>
      {images.length && !loading && (
        <Slide path={gameStore.currentUserPath} data={data} images={images} changeSlide={changeSlide} />
      )}
    </Slides>
  );
});

const Slides = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
